import Dashboard from "../Dashboard/Dashboard";
import ErrorPage from "./ErrorPage";
import TestLibrary from "../TestLibrary/TestLibrary";
import Createtest from '../CreateTest/createtest'
import ManageSchedule from "../ManageSchedule/ManageSchedule";
import ManageTest from "../ManageTest/ManageTest";
import Userassessment from "../UserAssessment/Userassessment";
import Schedule from '../Schedule/Schedule'
import Editor from '../Editor/Editor'
import GreetingPage from '../Editor/Evaluation/GreetingPage'
import Login from "../Login/Login";
import ProtectedRoute from "./ProtectedRoute";
// import libraries
import { Route, Routes } from "react-router-dom";
import InsideReport from "../Report/InsideReport";
import PercentageMatching from "../Report/Percentagetable";
import ResetPassword from "../Navbar/ResetPassword";
import Registration from "../Registration/Registration";
import ManageEvent from "../Event/ManageEvent";
import ManageUsers from "../Navbar/ManageUser/ManageUsers";
import { Settings } from "../Navbar/Settings/Settings";
import AdminDashboard, { ShowAssessmentAccordingToStatus } from "../Admin/AdminDashboard";
import TemplateBundle from "../ManageTest/TemplateBundle/TemplateBundle";
// import Editor from '../Editor/Editor'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route index path="login" element={<Login />} />
      <Route path='/resetpassword' element={<ResetPassword />} />
      {process.env.REACT_APP_IS_SHOW_REGISTER_EVENT === 'true' && <Route path="/register/:q" element={<Registration />} />}
      <Route path="/" element={<ProtectedRoute isAdmin={true} />}>
        <Route index element={<Dashboard />} />
        <Route path="testlibrary/" element={<TestLibrary />} />
        <Route path="scheduletest/:testId" element={<Schedule />} />
        <Route path="Createtest/" element={<Createtest />} />
        <Route path="template/" element={<ManageTest />} />
        <Route path="templatebundle/" element={<TemplateBundle />} />
        <Route path="settings" element={<Settings />} />

        <Route path="manageschedule/" element={<ManageSchedule />} />
        <Route path="report" element={<InsideReport />} />
        <Route path="insights" element={<PercentageMatching />} />
        <Route path="manageevent/" element={<ManageEvent />}></Route>
        <Route path="/manageusers" element={<ManageUsers />} />
        {process.env.REACT_APP_IS_SHOW_ADMIN_DASHBOARD === 'true' &&
          <Route path='/admidashboard' element={<AdminDashboard />} />
        }

        {/* <Route path="/assessmentdashboard" element={<ShowAssessmentAccordingToStatus />} /> */}
      </Route>

      {/* <Route path="/"  element={<ProtectedRoute  isAdmin={false}/>}> */}
      {/* <Route index path="result" element={<GreetingPage />} />
        </Route> */}

      <Route path="assessment/" element={<ProtectedRoute isAdmin={false} />}>
        <Route path="result" element={<GreetingPage />} />
        <Route index element={<Userassessment />} />
        <Route path="editor" element={<Editor />} />
      </Route>

      {/* Global error handling */}
      <Route path="*" element={<ErrorPage />} />


      {/* <Route index element={<ProtectedRoute Component={Dashboard} isAdmin={true} />} />
        <Route path="testlibrary/" element={<ProtectedRoute Component={TestLibrary} isAdmin={true} />} />
        <Route path="scheduletest/:testId" element={<ProtectedRoute Component={Schedule} isAdmin={true} />} />
        <Route path="Createtest/" element={< ProtectedRoute Component={Createtest} isAdmin={true} />} />
        <Route path="manageschedule/" element={<ProtectedRoute Component={ManageSchedule} isAdmin={true} />} />
        <Route path="greeting/" element={<ProtectedRoute Component={GreetingPage} isAdmin={true} />} /> */}


      {/* <Route path="managetest/"> */}
      {/* <Route path="managetest/" element={<ProtectedRoute Component={Userassessment} />} isAdmin={true} />
        <Route path="editor/" element={<ProtectedRoute Component={Editor} />} isAdmin={true} /> */}
      {/* </Route>*/}



    </Routes>
  )
}