// import react components and files
import React, { useState } from 'react';
import { calculateStep, calculateValidTillTime, getISOString, getUTCString } from './timePickerUtils';
// import { calculateStep } from './timePickerUtils';
import FromTimePicker from './FromTimePicker/FromTimePicker';
import ValidTillTimePicker from './ValidTillTimer/ValidTillTimePicker';
// import libraries 
// import styles
import './timepicker.scss'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

function TimePickerComp({ setSelectedTime, toShowTime, setToShowTime, color }) {
  const [stepValue, setStepValue] = useState(15);

  const stepOptions = [15, 30, 45, 60];

  const handleOnChange = (time) => {
    setSelectedTime(getISOString(time));
    setToShowTime(time)
  };



  return (
    <div className='schedule_time_picker'>
      {/* <section> */}
      <FromTimePicker
        handleOnChange={handleOnChange}
        stepValue={stepValue}
        setStepValue={setStepValue}
        stepOptions={stepOptions}
        toShowTime={toShowTime}
        color={color}
      />

      {/* <ValidTillTimePicker
        validTillTime={validTillTime}
      /> */}
      {/* </section> */}
    </div>
  );
}
export default TimePickerComp