import Cards from "./Cards";
import LoaderUtility from "../Loader/Loader";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { usePastAssessment } from "../hooks/useUserAssessment";

function Past() {
  let userid = getValueFromSession('username')
  const { loader, data } = usePastAssessment(userid, 'completed', 'completed')

  return <>
    {loader
      ? <LoaderUtility size={15} loading={loader} />
      : <Cards data={data} />}
  </>;
}

export default Past;
