import React, { useEffect, useState } from "react";
import TestTemplateTable from "./TestTemplatesTable/TestTemplateTable";
import useLibraryCardsData from "../hooks/useLibraryCardsData";
import ViewDetails from "../TestLibrary/LibraryCards/ViewDetails";
import Filters from "../TestLibrary/Filters/Filters";
import { filtered } from "../TestLibrary/LibraryCards/Libraryutility";
import Drawer from "../CustomDrawer/Drawer";
import { useArchiveTemplate } from "../hooks/useMarkArchive";
import { toggleSelectAll } from "./managetestutils";
import { useCheckCheckBoxHeader } from "./useManageTemplate";
import Archived from "./ArchivedTemplate/ArchiveTemplate";
import LoaderUtility from "../UtilityFunctions/Loader_utility";
// import libraries
import { IoMdArchive } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { MdOutlineArchive } from "react-icons/md";
// import styles
import "./managetest.scss";

const ManageTest = () => {
  const [modalData, setModalData] = useState({ modal: false, item: null });
  const [searchQuery, setSearchQuery] = useState("");

  const [filterOptions, setFilterOptions] = useState({
    selectedLeague: "all",
    selectedTrack: "all",
    searchQuery: "",
    selectedTemplate: 'all'
  });
  const [archiveIds, setArchiveIds] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isChange, setIsChange] =useState(false)
  const {
    filteredData,
    uniqueTracks,
    uniqueLeagues,
    loader,
    setFilteredData,
    fetchLibraryCards,
    templateBundleNames,
    setTemplateBundle,
    getBundles,
  } = useLibraryCardsData();
  const { archiveTemplate } = useArchiveTemplate();
  let data = filtered(searchQuery, filterOptions, filteredData);
  const size = 20;
  const cursor = "pointer";
  // console.log("archiveIds: ", archiveIds);

  useCheckCheckBoxHeader(selectedRows, setSelectAll, archiveIds);

  const handleFilterChange = (newFilterOptions) => {
    setFilterOptions(newFilterOptions);
  };
  const showDetails = (card) => {
    setModalData({ modal: true, item: card });
  };

  const handleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleToggleSelectAll = (data) => {
    toggleSelectAll(
      selectAll,
      data,
      setSelectedRows,
      setSelectAll,
      setArchiveIds
    );
  };

  const archiveUnarchive = async (rowsIds, isArchived) => {
    await archiveTemplate(rowsIds, isArchived);
    // fetch again for new data
  };

  const handleFetchAgain = async () => {
    await fetchLibraryCards();
  };

  const handleFilterArchiveRows = (rowsIds, alldata, setFilter) => {
    const updatedData = alldata.filter((row) => {
      return !rowsIds.includes(row.testid);
    });
    setFilter(updatedData);
    setSelectedRows([]);
    setArchiveIds([]);
  };

  function tocapitilizde(str) {
    return str[0].toUpperCase() + str.slice(1, str.length);
  }
  
  
useEffect(()=>{
  getBundles()
},[isChange])
const toggleUncheckAll=()=>{
  setSelectedRows([]);
    setArchiveIds([]);
}

const chnageOnGenarte=async ()=>{
  await fetchLibraryCards()
  data = filtered(searchQuery, filterOptions, filteredData)

}
  // console.log("templatedata", data)
  return (
    <section className="test_template">
      <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#archive"
        content="Archive selected"
        place="top"
      />
      <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#show"
        content="Show archived"
        place="top"
      />
      <section>
        <Filters
          onFilterChange={handleFilterChange}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          uniqueTracks={uniqueTracks}
          uniqueLeagues={uniqueLeagues}
          templateBundleNames={templateBundleNames}
          selectedRows={selectedRows}
          toggleUncheckAll={toggleUncheckAll}
          data={data}
          callChange={(templatedata)=>setTemplateBundle(templatedata)}
          chnageOnGenarte={chnageOnGenarte}
        />
      </section>

      <section className="test_template_content">
        {loader ? (
          <section style={{ marginTop: "2.75rem" }}>
            <LoaderUtility loading={loader} />
          </section>
        ) : (
          <>
            <section className="template_details_actions">
              <section className="template_details">
                {archiveIds?.length} row(s) selected of {data?.length} row(s)
              </section>
              <section className="archive_buttons">
                <button
                  className="left-half"
                  id="archive"
                  disabled={archiveIds?.length === 0}
                  onClick={async () => {
                    handleFilterArchiveRows(archiveIds, data, setFilteredData);
                    await archiveUnarchive(archiveIds, true);
                  }}
                >
                  <IoMdArchive cursor={cursor} size={size} />
                </button>
                <button
                  className="right-half"
                  id="show"
                  disabled={archiveIds?.length > 0}
                  onClick={() => handleDrawer("archive")}
                >
                  <MdOutlineArchive cursor={cursor} size={size} />
                </button>
              </section>
            </section>
            <TestTemplateTable
              data={data}
              showDetails={showDetails}
              tocapitilizde={tocapitilizde}
              selectAll={selectAll}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setArchiveIds={setArchiveIds}
              archiveIds={archiveIds}
              loading={loader}
              handleToggleSelectAll={handleToggleSelectAll}
            />
            {modalData.modal && (
              <ViewDetails modalData={modalData} setModalData={setModalData} />
            )}
          </>
        )}
      </section>

      {drawerOpen && (
        <Drawer isOpen={drawerOpen} onClose={handleDrawer}>
          <Archived
            size={size}
            cursor={cursor}
            archiveUnarchive={archiveUnarchive}
            handleFilterArchiveRows={handleFilterArchiveRows}
            handleToggleSelectAll={handleToggleSelectAll}
            selectAll={selectAll}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setArchiveIds={setArchiveIds}
            archiveIds={archiveIds}
            showDetails={showDetails}
            tocapitilizde={tocapitilizde}
            handleFetchAgain={handleFetchAgain}
          />
        </Drawer>
      )}
    </section>
  );
};

export default ManageTest;
