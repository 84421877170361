import React, { useState } from "react";
import "./Evalform.scss";

export default class DynamicForm extends React.Component {
  state = {};
  constructor(props) {
    super(props);

    // console.log("props constructor", props);
    // this.state={
    //   evaluationResul:[],
  }

  static getDerivedStateFromProps(Props, State) {
    // console.log("gds:p:s", Props, State);
    return null;
  }

  clearTimer(timeOutid) {
    while (timeOutid--) {
      clearTimeout(timeOutid);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.onSubmit) this.props.onSubmit(this.state);
  };

  toCheckAnswers = (fromPrac) => {
    if (this.props.toCheckAnswers)
      this.props.toCheckAnswers(this.state, fromPrac);
  };

  toEvaluate = (finished) => {
    if (this.props.toEvaluate) this.props.toEvaluate(this.state, finished);
  };


  tochangevalues = (key, value) => {
    // const { tochangevalues } = useToEvaluate();
    // tochangevalues(key,value);
    if (this.props.tochangevalues) this.props.tochangevalues(key, value);
  };

  callbackfunc = () => {
    this.toEvaluate()
  }

  onChange = (e, key, type = "single") => {
    // console.log(`${e.target.value} type ${type}`);
    // if(props)
    // this.tochangevalues(key, e.target.value);
    if (type === "single") {
      // console.log(key, e.target.value);
      if (e.target.value === "") {
        console.log("No value", key)
        this.props.setKey(key)
        this.props.setValue(e.target.value)
        this.tochangevalues(key, e.target.value);
      }
      this.setState(
        {
          [key]: e.target.value,
        },
        () => { }
      );

      this.props.statenp(
        {
          ...this.props.npvalue,
          [key]: e.target.value,
        }
      )
      // console.log("key", key)
      // console.log("e.target.value", e.target.value)
    } else {
      // Array of values (e.g. checkbox): TODO: Optimization needed.
      let found = this.state[key]
        ? this.state[key].find((d) => d === e.target.value)
        : false;

      if (found) {
        let data = this.state[key].filter((d) => {
          return d !== found;
        });
        this.setState({
          [key]: data,
        });
        this.props.statenp({
          ...this.props.npvalue,
          [key]: data,
        })
      } else {
        console.warn("found", key, this.state[key]);
        // this.setState({
        //   [key]: [e.target.value, ...this.state[key]]
        // });
        let others = this.state[key] ? [...this.state[key]] : [];
        this.setState({
          [key]: [e.target.value, ...others],
        });
        this.props.statenp({
          ...this.props.npvalue,
          [key]: [e.target.value, ...others],
        })
      }
    }
  };

  renderForm = () => {
    // console.log("inside render");
    let model = this.props.model;
    // let model = getJsonWithEmptyValueUpdate();
    // console.log("model", model);
    let defaultValues = this.props.defaultValues;
    let formUI = model.map((m, i) => {
      // console.log("m", m)
      let key = m.key;
      let type = m.type || "text";
      let props = m.props || {};
      let name = m.name;
      let value = m.value;
      let index = i + 1;

      // console.log("value", value);

      let target = key;
      value = this.state[target] || value;

      // console.log("value", typeof value);
      let input = (
        <input
          {...props}
          className="input"
          type={type}
          key={key}
          name={name}
          value={value}
          aria-label={m.label}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => {
            this.onChange(e, target);
          }}
        />
      );

      if (type == "radio") {
        // console.log("m.options", m.options);
        input = m?.options?.map((o) => {
          let checked = o.value == value;
          return (
            <div className="form-group-input-label" key={"fr" + o.key}>
              <input
                {...props}
                className="form-input"
                type={type}
                key={o.key}
                name={o.name}
                checked={checked}
                value={o.value}
                aria-label={m.label}
                onChange={e => {
                  this.onChange(e, o.name);
                }}
              />
              <label aria-label={o.label} key={"ll" + o.key}>{o.label}</label>
            </div>
          );
        });
        input = <div className="form-group-radio">{input}</div>;
      }

      if (type == "select") {
        input = m?.options?.map((o) => {
          let checked = o.value == value;
          return (
            <option
              {...props}
              className="form-input"
              key={o.key}
              value={o.value}
            >
              {o.value}
            </option>
          );
        });

        //console.log("Select default: ", value);
        input = (
          <select
            value={value}
            onChange={(e) => {
              this.onChange(e, m.key);
            }}
          >
            {input}
          </select>
        );
      }

      if (type == "checkbox") {
        input = m?.options?.map((o) => {
          //let checked = o.value == value;
          let checked = false;
          if (value && value.length > 0) {
            checked = value.indexOf(o.value) > -1 ? true : false;
          }
          //console.log("Checkbox: ", checked);
          return (
            <div className="form-group-input-label" key={"cfr" + o.key}>
              <input
                {...props}
                className="form-input"
                type={type}
                key={o.key}
                name={o.name}
                checked={checked}
                value={o.value}
                aria-label={m.label}
                onChange={e => {
                  this.onChange(e, m.key, "multiple");
                }}
              />
              <label htmlFor={o.label} aria-label={o.label} key={"ll" + o.key}>{o.label}</label>
            </div>
          );
        });

        input = <div className="form-group-checkbox">{input}</div>;
      }

      return (
        <div key={"g" + key} className="form-group">
          <label className="form-label" key={"l" + key} htmlFor={m.label}>
            {`${index})  ${m.label}`}
          </label>
          {input}
        </div>
      );
    });
    return formUI;
  };

  evaluationSubmit = () => {
    return (
      <>
        <div className="pre-next">
          {!this.props.isFirstStep ? (
            <button
              className="link-button small"
              onClick={() => {
                this.toEvaluate();
                // ToEvaluate()
                // this.updateValue();
                this.props.back();
              }}
            >
              <i class="fa-solid fa-chevron-left"></i>
              <span style={{ marginLeft: "5px" }}>Previous</span>
            </button>
          ) : (
            <button className="link-button small disabled">
              <i class="fa-solid fa-chevron-left"></i>
              <span style={{ marginLeft: "5px" }}>Previous</span>
            </button>
          )}
          {!this.props.isLastStep ? (
            <button
              className="link-button small"
              onClick={() => {
                this.toEvaluate();

                this.props.next();
              }}
            >
              <span style={{ marginRight: "5px" }}>Next</span>
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          ) : (
            <button className="link-button small disabled">
              <span style={{ marginRight: "5px" }}>Next</span>
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          )}
          {!this.props.isLastStep ? (
            <>
              <button
                onClick={() => {
                  // this.toEvaluate();
                }}
                type="button"
                // id="button-1"
                // disabled
                className="button primary small disabled"
              >
                Finish
              </button>
            </>
          ) : (
            <button
              type="button"
              // id="button-1"
              // disabled
              className="button primary small"
              onClick={() => {
                let finished = true;
                this.clearTimer(parseInt(sessionStorage.getItem("timeid")));
                this.toEvaluate(finished);
              }}
            >
              Finish
            </button>
          )}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className="form-actions">
          <div className="form-actions-btn-title">
            <h4 class="form-actions-title">
              Answer the following
            </h4>
            {this.evaluationSubmit()}
          </div>
        </div>
        <div className="form-render evalindex">
          <div className={this.props.className}>
            <form
              id="my-form"
              className="dynamic-form"
              onSubmit={(e) => {
                this.onSubmit(e);
              }}
            >
              {this.renderForm()}
            </form>
          </div>
        </div>
        {/* <div className="form-actions">{this.evaluationSubmit()}</div> */}
      </>
    );
  }
}
