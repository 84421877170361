import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig";
import LoaderUtility from "../../Loader/Loader";
import Congrats from "./Celebration/Congrats";
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";
import AppContext from "../../AppContext/AppContext";
import "./EvalPanel.scss";
import { useAuth } from "../../hooks/useAuth";
import { fetchData } from "../../ApiUtils/fetchData";
import Answerdialog from "../../ManageSchedule/TableData/ExpandableRow/Answerdialog";
const GreetingPage = () => {
  const [isModal, setIsMOdal] = useState(false);
  const [markComplete, setMarkComplete] = useState(true);
  const [showbtn, setshowbtn] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [variable, setvariable] = useState(true);
  const navigate = useNavigate();
  const [dialog, setDialog] = useState(false);
  const { state, pathname } = useLocation();
  const [dialogdata, setDialogData] = useState([]);
  window.history.pushState(null, "", pathname);
  window.onpopstate = function () {
    window.history.pushState(null, "", pathname);
  };

  const { logout } = useAuth();
  const navbtn = useContext(AppContext);
  // console.log("state", state);
  const BaseURI = new ApiConfig().BaseURI;
  const user_group = sessionStorage.getItem("usergroup");
  const showResult = state?.showResult;
  console.log("showResult", showResult);
  console.log("showResult", showResult);
  // let evaluationResult = JSON.parse(sessionStorage.getItem('evaluationResult'));
  let evaluationResult = JSON.parse(getValueFromSession("evaluationResult"));
  let score;
  let no_of_correct_answers;
  let no_of_questions;
  let totalmarks;

  let start_time;
  let temp_end_time;
  let end_time;
  let userid;
  let module_name;
  let status;
  let attempted;
  // console.log('evaluationResult.length', evaluationResult != undefined)
  if (evaluationResult != undefined) {
    // console.log("evaluationResult", evaluationResult);
    score = evaluationResult.score;
    no_of_correct_answers = evaluationResult.correct;
    no_of_questions = evaluationResult.questions;
    totalmarks = evaluationResult.maxscore;
    attempted = evaluationResult.attempted;

    // start_time = sessionStorage.getItem("start_time");
    // temp_end_time = Date.now();
    // end_time = new Date(temp_end_time).toISOString();
    userid = getValueFromSession("username");
    module_name = sessionStorage.getItem("module_name");
    status = "complete";
  }
  // reset=false
  console.log("score", score);
  console.log("no_of_correct_answers", no_of_correct_answers);
  console.log("no_of_questions", no_of_questions);
  console.log("totalmarks", totalmarks);

  async function handleData() {
    let Api = new ApiConfig();
    let scheduleid = getValueFromSession("scheduleid");
    let result = await fetchData(
      `getuserinfoforreport?scheduleid=${scheduleid}&userid=${userid}`
    );
    setDialogData(result?.data);
    setDialog(true);
  }

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    // borderColor: "olive",
  };
  return (
    <>
      <section className="pyui-evalpanel-container">
        <div className="pyui-evalpanel-content" style={{ width: "auto" }}>
          {showResult ? (
            <>
              <div className="eval-result">
                <span className="eval-result-content">
                  <span className="eval-result-title">Correct answers </span>
                  <span className="success">{no_of_correct_answers}</span>
                  <span className="eval-result-outof">
                    {" "}
                    / {no_of_questions}{" "}
                  </span>
                </span>
                <span className="eval-result-content">
                  <span className="eval-result-title"> Your score </span>
                  <span className="information">{score}</span>
                  <span className="eval-result-outof">/ {totalmarks} </span>
                </span>
              </div>
            </>
          ) : (
            <>
              <p className="pyui-evalpanel-text">
                Your result has been submitted to your organisation.
              </p>
            </>
          )}
          <Greetingbutton
            showbtn={showbtn}
            navigate={navigate}
            logout={logout}
            // toSetEvaluationUserScore={toSetEvaluationUserScore}
            // data={data}
            navbtn={navbtn}
            handleData={handleData}
            showResult={showResult}
          />
          {/*  */}
        </div>

        {dialog && (
          <Answerdialog
            data={dialogdata}
            dialog={dialog}
            setDialog={setDialog}
            userid={getValueFromSession("username")}
            scheduleid={getValueFromSession("scheduleid")}
          />
        )}
      </section>
    </>
    // )}
    // </>
  );
};

export default GreetingPage;

function Greetingbutton({ showbtn, navigate, logout, handleData,showResult }) {
  return (
    <div className="eval-result-actions">
      {/* {console.log('showbtn', showbtn)} */}
      {showbtn ? (
        <>
          {/* <button
            className="button primary"
            onClick={() => {
              sessionStorage.removeItem("evaluationResult");
              sessionStorage.removeItem("results");
              sessionStorage.removeItem("module_name");
              sessionStorage.removeItem("start_time");
              sessionStorage.removeItem("newModel");
              sessionStorage.removeItem("model");
              sessionStorage.removeItem("duration");
              sessionStorage.removeItem("randomexe");
              sessionStorage.removeItem("showresult")
              navigate("/");
              navbtn.updatenavbtn(true);
            }}
          >
            Back to Quizzes
          </button> */}
          {(showResult && process.env.REACT_APP_SHOW_RESULT_BREAKDOWN=='true' )&& (
            <button onClick={(e) => handleData()} className="button secondary">
              Score breakdown
            </button>
          )}

          <button
            className="button primary small"
            type="submit"
            onClick={() => {
              // toSetEvaluationUserScore(data);

              sessionStorage.removeItem("evaluationResult");
              sessionStorage.removeItem("results");
              sessionStorage.removeItem("module_name");
              sessionStorage.removeItem("start_time");
              sessionStorage.removeItem("newModel");
              sessionStorage.removeItem("model");
              sessionStorage.removeItem("duration");
              sessionStorage.removeItem("randomexe");
              sessionStorage.removeItem("showresult");
              sessionStorage.clear();
              logout();
            }}
          >
            Back to login
          </button>
        </>
      ) : (
        <button
          className="completed-button"
          type="submit"
          onClick={(e) => {
            // toSetEvaluationUserScore(data);
            sessionStorage.removeItem("evaluationResult");
            sessionStorage.removeItem("results");
            sessionStorage.removeItem("module_name");
            sessionStorage.removeItem("start_time");
            sessionStorage.removeItem("newModel");
            sessionStorage.removeItem("model");
            sessionStorage.removeItem("duration");
            sessionStorage.removeItem("randomexe");
            sessionStorage.removeItem("showresult");
            sessionStorage.clear();
            logout(e);
            navigate("/login");
          }}
        >
          Back to login
        </button>
      )}
    </div>
  );
}
