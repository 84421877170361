import { useState } from "react"
import { fetchData } from "../../../ApiUtils/fetchData"
import Answerdialog from "./Answerdialog"

export default function Answerdata({userid,scheduleid,index}){

    const [data,setTableData]=useState([])
    const [dialog,setDailog]=useState(false)
    const  getUserReportData1=async(userid,scheduleid,index)=>{
        let data = await fetchData(`getuserinfoforreport?scheduleid=${scheduleid}&userid=${userid}`)
        setTableData(data?.data)
        setDailog(true)
        console.log("DATA is",data)
    }


    return (
        <>
        <div >
        <button className="button secondary small data_actions" onClick={(e)=>getUserReportData1(userid,scheduleid,index)}>View</button> 
        {dialog && <Answerdialog data={data} dialog={dialog} setDialog={setDailog} userid={userid} scheduleid={scheduleid}/>}
        </div></>
    )

}