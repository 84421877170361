// import react components and files
import React, {  useState } from "react";
import Search from "../Search/Search";
import {
  handleLeagueChange,
  handleSearchChange,
  handleTemplateChange,
  handleTrackChange,
  handlecreatedchange,
} from "./filterutility";
// import styles
import { useNavigate } from "react-router";
import "./filter.scss";
import TestBundleCRUD from "../../ManageTest/TestBundle/TestBundleCRUD/TestBundleCRUD";

const Filters = ({
  onFilterChange,
  setSearchQuery,
  searchQuery,
  uniqueLeagues,
  uniqueTracks,
  templateBundleNames,
  selectedRows,
  data,
  setSelectedRows,
  callChange,
  toggleUncheckAll,
  chnageOnGenarte,
}) => {
  const [selectedLeague, setSelectedLeague] = useState("all");
  const [selectedTrack, setSelectedTrack] = useState("all");
  const [selectedTemplateName, setSelectedBundle] = useState("all");
  const [selectcreate, setSelectedcreate] = useState("All templates");
  const [createdoptions, setcreatedoptions] = useState([
    "All templates",
    "My templates",
  ]);
  let navigate = useNavigate();
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const renderOptions = (options) => (
    <>
      <option value="all">All</option>
      {options
        ?.filter((item) => item !== "all")
        ?.map((option) => (
          <option key={option} value={option}>
            {capitalizeFirstLetter(option)}
          </option>
        ))}
    </>
  );

  return (
    <section className="pyui_filters">
      <section>
        <button
          className="button link-button"
          onClick={(e) => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </section>
      <section>
        <Search
          className="searchFilter"
          value={searchQuery}
          handleSearchChange={(event) =>
            handleSearchChange(
              setSearchQuery,
              onFilterChange,
              selectedTrack,
              selectedLeague,
              selectcreate,
              selectedTemplateName,
              event
            )
          }
        />
      </section>

      <section>
        <label className="pyui_filters_league">
          Select level:
          <select
            value={selectedLeague}
            onChange={(event) =>
              handleLeagueChange(
                setSelectedLeague,
                onFilterChange,
                searchQuery,
                selectedTrack,
                selectcreate,
                selectedTemplateName,
                event
              )
            }
          >
            {renderOptions(uniqueLeagues)}
          </select>
        </label>
      </section>

      <section>
        <label className="pyui_filters_tracks">
          Select track:
          <select
            value={selectedTrack}
            onChange={(event) =>
              handleTrackChange(
                setSelectedTrack,
                onFilterChange,
                searchQuery,
                selectedLeague,
                selectcreate,
                selectedTemplateName,
                event
              )
            }
          >
            {renderOptions(uniqueTracks)}
          </select>
        </label>
      </section>

      {process.env.REACT_APP_SHOW_TEMPLATE_BUNDLE === 'true' && <section>
        <label className="pyui_filters_tracks">
          Select template bundle:
          <select
            value={selectedTemplateName}
            onChange={(event) =>
              handleTemplateChange(
                setSelectedBundle,
                onFilterChange,
                searchQuery,
                selectedLeague,
                selectedTrack,
                selectcreate,
                event
              )
            }
          >
            {renderOptions(templateBundleNames)}
          </select>
        </label>
      </section>}
      {process.env.REACT_APP_SHOW_TEMPLATE_BUNDLE === 'true' && <section>
        <TestBundleCRUD
         selectedRows={data?.filter(item=> selectedRows.includes(item.testid)) }
         callChange={(templateBunles, removedBundle)=>{
          if(removedBundle === selectedTemplateName){
              setSelectedBundle('All')
          }
          callChange(templateBunles)
        }}
         toggleUncheckAll={toggleUncheckAll}
         chnageOnGenarte={chnageOnGenarte}
         />
      </section>}
      <section>
        <label className="pyui_filters_tracks">
          Show :
          <select
            value={selectcreate}
            onChange={(event) =>
              handlecreatedchange(
                setSelectedcreate,
                onFilterChange,
                searchQuery,
                selectedLeague,
                selectedTrack,
                selectedTemplateName,
                event
              )
            }
          >
            {/* <option value="all">All</option> */}
            {createdoptions.map((option) => (
              <option key={option} value={option}>
                {capitalizeFirstLetter(option)}
              </option>
            ))}{" "}
          </select>
        </label>
      </section>
      
    </section>
  );
};

export default Filters;
