import * as XLSX from 'xlsx'; // For Excel
import Papa from 'papaparse'; // For CSV

export const currDateChecker = (state, isScheduletime=false) => {
  console.log("Stare",state)
  if(state){
    
    if ( isScheduletime && (state?.currentTestData?.scheduletime !== null || state?.currentTestData?.scheduletime !== undefined) ) {
     
      const now = new Date(state?.currentTestData?.scheduletime);
      const currentHour = now.getHours().toString().padStart(2, '0'); 
      const currentMinutes = now.getMinutes().toString().padStart(2, '0'); 
      
      const timeString = `${currentHour-6}:${currentMinutes-30}`;
      const timeString1 = `${currentHour}:${currentMinutes}`;

      return {date:now,showtime:timeString1,time:timeString1}
    }
  
     if (state?.currentTestData?.validtill !== null && state?.currentTestData?.validtill !== undefined) {
     
      const now = new Date(state?.currentTestData?.validtill);
      const currentHour = now.getHours().toString().padStart(2, '0'); 
      const currentMinutes = now.getMinutes().toString().padStart(2, '0'); 
      
      const timeString = `${currentHour-6}:${currentMinutes-30}`;
      const timeString1 = `${currentHour}:${currentMinutes}`;

      return {endDate:now,endShowtime:timeString1,endTimed:timeString1}
    }
  }
    else {
      
      if(isScheduletime){
        const now = new Date();
        const currentHour = now.getHours().toString().padStart(2, '0'); 
        const currentMinutes = now.getMinutes().toString().padStart(2, '0'); 
        const timeString = `${currentHour}:${currentMinutes}`;
        return{date:now,showtime:timeString,time:timeString}
      }
      const now = new Date();
      const hours =parseInt(process.env.REACT_APP_HOURS)
      const minutes =parseInt(process.env.REACT_APP_MINUTES)
      now.setUTCHours(now.getUTCHours()+hours,(now.getUTCMinutes() + minutes));
      const currentHour = now.getHours().toString().padStart(2, '0'); 
      const currentMinutes = now.getMinutes().toString().padStart(2, '0'); 
      const timeString = `${currentHour}:${currentMinutes}`;
      
    
      return{endDate:now,endShowtime:timeString,endTimed:timeString}
    }


}

export const currDateChecker1 = (state) => {
  if (state?.currentTestData?.scheduletime !== null || state?.currentTestData?.scheduletime !== undefined) {

      let date=new Date(state?.currentTestData?.scheduletime)
      const currentHour = date.getHours().toString().padStart(2, '0'); 
      const currentMinutes = date.getMinutes().toString().padStart(2, '0'); 
      const timeString = `${currentHour}:${currentMinutes}`;   
       return timeString
  }
    else {
      let date=new Date()
      const currentHour = date.getHours().toString().padStart(2, '0'); 
      const currentMinutes = date.getMinutes().toString().padStart(2, '0'); 
       const timeString = `${currentHour}:${currentMinutes}`;   
      return timeString
    }

}
export const showResultChecker = (state) => {
  if (state?.currentTestData) {
    if (state?.currentTestData?.showresult !== null || state?.currentTestData?.showresult !== undefined) {
      return state?.currentTestData?.showresult
    }
    
  }
  return false
}
export const markingSchemeChecker = (state) => {
  if (state?.currentTestData) {
    if (state?.currentTestData?.markingscheme !== null || state?.currentTestData?.markingscheme !== undefined) {
      return state?.currentTestData?.markingscheme
    }
  }
  return 'pravidefault'
}
export const toNotifyCheckerChecker = (state) => {
  if (state?.currentTestData) {
    if (state?.currentTestData?.tonotify !== null || state?.currentTestData?.tonotify !== undefined) {
      return state?.currentTestData?.tonotify
    }
  }
  return false
}

export const combineDateTimeAsISO = (timeString, dateString) => {

  console.log("timeString", timeString)
  console.log("dateString", dateString)
  // Parsing ISO strings into Date objects
  const date1 = new Date(timeString);
  const date2 = new Date(dateString);
console.log('date1 , 2', date1, date2)
  // Creating a new Date object with the date from the second ISO string and time from the first ISO string
  const combinedDate = new Date(date2);
  combinedDate.setUTCHours(date1.getUTCHours());
  combinedDate.setUTCMinutes(date1.getUTCMinutes());
  combinedDate.setUTCSeconds(date1.getUTCSeconds());

  // console.log("combinedDate", combinedDate.toISOString())
  // Getting the ISO string from the combined Date object
  const finalISOString = combinedDate.toISOString();

  return finalISOString
}

const createValidTillDateTime = (timeString) => {
  // Creating a Date object from the final ISO string
  const combinedDate = new Date(timeString);

  // Adding 2 hours to the Date object

  // console.log('validTillISOString ',combinedDate)
  const hours =parseInt(process.env.REACT_APP_HOURS)
  const minutes =parseInt(process.env.REACT_APP_MINUTES)
  combinedDate.setUTCHours(combinedDate.getUTCHours()+hours,(combinedDate.getUTCMinutes() + minutes));
  // combinedDate.setMinutes((combinedDate.getMinutes() + 30));

  // console.log('validTillISOString ',(combinedDate.getMinutes() + 30))
  // Getting the updated ISO string with 2 hours added
  const validTillISOString = combinedDate.toISOString();
  // console.log('validTillISOString ',validTillISOString)
  return validTillISOString
}

export const validTillTimeString = (timeString, dateString) => {
  const combinedISOString = combineDateTimeAsISO(timeString, dateString)

  const validTillISOString = createValidTillDateTime(combinedISOString)

  return [combinedISOString, validTillISOString]
}

export const ScheduleTheTest = (selectedTime, dateValue, ScheduleTest, testId, selectMarkingScheme, isChecked, useridArr, isToNotify, status, selectedUserids, removeUseridArr,title,endDateValue, endTime, isEndDateChanged, isEndTimeChanged) => {
  // console.log("validtill", selectedTime)
  // console.log("scheduletime", dateValue)
  // console.log('status', status)
  // console.log('selected',selectedUserids)
  console.log('title ',title,endDateValue)
  let combinedISOString;
  let validTillISOString;
  if (selectedTime !== null && dateValue !== null) {
    [combinedISOString, validTillISOString] = validTillTimeString(selectedTime, dateValue);
  }

  if (isToNotify) {
    console.log("if 1st")
    ScheduleTest(parseInt(testId), combinedISOString, selectMarkingScheme, isChecked, validTillISOString, useridArr, isToNotify, status, selectedUserids,removeUseridArr, title, endDateValue, endTime, isEndDateChanged, isEndTimeChanged)
  } else {
    console.log("else 2nd")
    ScheduleTest(parseInt(testId), combinedISOString, selectMarkingScheme, isChecked, validTillISOString, useridArr,undefined , status, selectedUserids, removeUseridArr, title, endDateValue, endTime, isEndDateChanged, isEndTimeChanged)
  }
}

export const nextSection = (setCurrentSection, currentSection) => {
  setCurrentSection(currentSection + 1);
};

export const prevSection = (setCurrentSection, currentSection) => {
  setCurrentSection(currentSection - 1);
};

export const customTimeString = () => {
  const d = new Date();
  const hours = d.getHours() % 12 || 12;
  const ampm = d.getHours() >= 12 ? 'PM' : 'AM';
  const minutes = d.getMinutes().toString().padStart(2, '0');
  const seconds = d.getSeconds().toString().padStart(2, '0');

  const time = `${hours}:${minutes}:${seconds}`;
  // console.log(time); 
  return time;

}


export const handleFileUpload = (file, setFilename, setFileTypeError, setData) => {
  if (file) {
    // Check if the selected file is CSV or Excel
    const fileType = file.type;
    console.log("fileType: " + fileType)
    if (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFilename(file.name)
      readExcelFile(file, setData)
      setFileTypeError(null);
    } else if (fileType === 'text/csv') {
      setFilename(file.name)
      readCSVFile(file, setData)
      setFileTypeError(null);
    } else {
      setFileTypeError("Please select a CSV or Excel file.");
    }
  } else {

  }
};

const readExcelFile = (file, setData) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setData(jsonData);
  };
  reader.readAsArrayBuffer(file);
};

const readCSVFile = (file, setData) => {
  Papa.parse(file, {
    header: true,
    complete: (results) => {
      const filteredData = results.data.slice(0, -1); // Remove last row
      setData(filteredData);
    },
    // error: (error) => setError(error.message),
  });
};
