import Renderdebugsolvereditor from "./Renderdebugsolvereditor";
import RendervisualsolverEditor from "./RendervisualsolverEditor";
import RenderImageEditor from "./RenderImageEditor";
import RenderreaderEditor from "./RenderreaderEditor";
import { langguagesHaveImage } from "./Hooks/useQuesAnsAssertTest";



const CustomizableRenderer = (renderprops) => {
  const { module, isShowEditor, isImageEditor, isTextEditor,setIsLimitExceed } = renderprops;
  const language = sessionStorage.getItem("language");
  const imglanguages=langguagesHaveImage || ['Visual Programming','Communication Skills','Basic Electronics', 'Mathematics Skills']
  if (
    (!imglanguages.includes(language)) &&
    (module === "debug" || module === "solver")
  ) {
    return <Renderdebugsolvereditor {...renderprops} setIsLimitExceed={setIsLimitExceed} language={language}/>;

  }
   else if (language === "Visual Programming" && module === "solver") {
    return <RendervisualsolverEditor {...renderprops} language={language}/>;

  } 
  else if ((imglanguages.includes(language)) && isShowEditor && isImageEditor) {
    return <RenderImageEditor {...renderprops} language={language}/>;
  } 
  else {
    return <RenderreaderEditor {...renderprops} language={language} />;
  }
};

export default CustomizableRenderer;
