import { useEffect, useRef, useState } from "react"
import { MdPerson } from "react-icons/md";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { useAuth } from "../hooks/useAuth";
// import { useNavigate, } from "react-router";
import { useNavigate, Link, Routes, Route } from 'react-router-dom'
import { FaKey, FaUserCog, FaUsers } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import ManageUsers from "./ManageUser/ManageUsers";

export default function Profile() {
  const { logout } = useAuth()
  const [dropDown, setDropDown] = useState(false)
  const navigate = useNavigate()
  let username = getValueFromSession('username')
  let menuRef = useRef();
  let role = getValueFromSession('role')
  const size = 20

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropDown(false);
      }

    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>

      <ul>


        <li ref={menuRef} style={{ position: "relative", }}>
          {console.log('username != undefined', username != undefined, username)}
          {(username !== undefined && username !== null) ? (
            <button
              tabIndex={1}
              title="Profile"
              className="pyui_avatar"
              onClick={() => {
                setDropDown(() => {
                  if (dropDown) return false;
                  return true;
                });
              }}
            >
              <MdPerson size={16} />
            </button>
          ) : (
            <></>
          )}
          {dropDown && (
            <>
              <div className="pyui_dropdown-profile">
                <ul className="pyui_dropdown-userlist">
                  {role === 'Admin' && (
                    <li>
                      <Link
                        tabIndex={1}
                        to="resetPassword"
                        className="pyui_profile"
                        title="Reset"
                        onClick={() => {
                          setDropDown(false);
                        }}>
                        <span className='title_icon'>
                          <FaKey size={size - 5} />
                          Reset Password
                        </span>
                      </Link>
                    </li>
                  )}
                  <li>
                    {(process.env.REACT_APP_SHOW_MANAGE_USERS === 'true' && role === 'Admin') && (
                      <Link
                        tabIndex={2}
                        to="manageusers"
                        className="pyui_profile"
                        title="Manage Users"
                        onClick={() => {
                          setDropDown(false);
                        }}>
                        <span className='title_icon'>
                          <FaUsers size={size - 5} />
                          Manage Users
                        </span>
                      </Link>
                    )}
                  </li>
                  <li>
                    {(process.env.REACT_APP_SHOW_ACCOUNT_SETTINGS === 'true' && role === 'Admin') && (
                      <Link
                        to={"settings"}
                        tabIndex={3}
                        className="pyui_profile"
                        title="Users Settings"
                        onClick={() => {
                          setDropDown(false);
                        }}>
                        <span className='title_icon'>
                          <FaUserCog size={size - 5} />
                          Accounts Settings
                        </span>
                      </Link>
                    )}
                  </li>
                  <li>
                    <div
                      tabIndex={4}

                      className="pyui_profile"
                      title="Logout"
                      onClick={(e) => {
                        logout(e);
                        //   clearTimer();
                        setDropDown(false);
                        username = undefined
                      }}>
                      <span className='title_icon'>
                        <MdLogout size={size - 5} />
                        Sign Out
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )}
        </li>
      </ul>
    </>
  )
}