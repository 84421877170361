import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ count, months, height ,label}) => {
  // console.log("data1",data1,"cate",categories)
  let series=  [{
        name: 'count',
        data: count
      }]

 let options= {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', 
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val ;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      categories:  months,
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val ;
        }
      }
    },
    title: {
      text: label,
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  }

  return (
    <div>
      <div id="chart" style={{background:'white'}}>
        <ReactApexChart options={options} series={series} type="bar" height={height || 350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

ApexChart.defaultProps = {
  height: 350
};


export default ApexChart;
