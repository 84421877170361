// import { Base64 } from 'js-base64'
import CryptoJS from 'crypto-js';

class ApiConfig {
  constructor() {

    //this.BaseURI = "https://dolphin-app-uzion.ondigitalocean.app/api"
    // this.BaseURI = "https://octopus-app-fu2mk.ondigitalocean.app/api";
    this.BaseURI = "https://whale-app-o692b.ondigitalocean.app/api";
    // this.BaseURI = "http://localhost:8090/api";


    // #################   uri after payment redirection    ################
    // this.RedirectUri='https://monkfish-app-jhnb8.ondigitalocean.app'
    //this.RedirectUri = 'https://goldfish-app-jiu9z.ondigitalocean.app'
    // this.RedirectUri='http://localhost:3000'
    // this.RedirectUri='http://192.168.1.19:3000/'


    this.client_public_key = 'baef7468287a44d8ac3634026d9fb8d1'
    this.client_secret_key = '181a2f3171117c1bd164c88b1171c1b83114fc1712121b12'
    // this.client_public_key=Base64.encode('baef7468287a44d8ac3634026d9fb8d1')

    // console.log("encode api key",Base64.encode('181a2f3171117c1bd164c88b1171c1b83114fc1712121b12'))
    // this.client_secret_key=Base64.encode('181a2f3171117c1bd164c88b1171c1b83114fc1712121b12')
  }


  addApiKey(uri) {

    // console.log(uri)
    // console.log("encode api key",Base64.encode(this.client_public_key))
    if (uri.includes('?')) {

      uri += `&clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`
    } else {
      uri += `?clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`
    }
    // console.log(uri)
    if (process.env.REACT_APP_SHOW_ISENCRYPT === "true") {
      const secretKey = 'don';
      const url = uri;
      const splitUrl = url.split('?');
      const baseUrl = splitUrl[0];
      const queryString = splitUrl[1];
      const encryptedQueryString = CryptoJS.AES.encrypt(queryString, secretKey).toString();
      const encodedQueryString = encodeURIComponent(encryptedQueryString);
      const encryptedUrl = `${baseUrl}?encodedQueryString=${encodedQueryString}`;
      return encryptedUrl
    }
    else {
      return uri;
    }


  }

  decode(uri) {

    const secretKey = 'don';
    let originalQueryString = decodeURIComponent(uri);
    const decryptedData = CryptoJS.AES.decrypt((originalQueryString), secretKey).toString(CryptoJS.enc.Utf8);
    const decryptedJSON = JSON.parse(decryptedData);
    return decryptedJSON
  }



  encryption(url1) {

    const secretKey = 'don';
    const url = url1
    const splitUrl = url.split('?');
    const baseUrl = splitUrl[0];
    const queryString = splitUrl[1];
    const encryptedQueryString = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    const encodedQueryString = encodeURIComponent(encryptedQueryString);
    const encryptedUrl = `${baseUrl}?encodedQueryString=${encodedQueryString}`;
    return encryptedUrl
  }

  getResetPassword() {
    return `${this.BaseURI}/resetpassword`
  }

  getLogin(username, password, ip) {
    let encryption;
    if (process.env.REACT_APP_SHOW_ISENCRYPT === "true") {
      encryption = this.encryption(`${this.BaseURI}/userLogin?username=${username}&password=${password}&ip=${ip}&clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`)
      // console.log("encryption",encryption)

    }
    else {
      encryption = `${this.BaseURI}/userLogin?username=${username}&password=${password}&ip=${ip}&clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`
    }

    return encryption;
  }

  getModules() {
    return `${this.BaseURI}/modules`
  }

  getModulesWiseQues(module) {
    return `${this.BaseURI}/exercises?module=${module}`
  }

  getModulesDebug() {
    return `${this.BaseURI}/exercises?module=reader`
  }

  getModulesReader() {
    return `${this.BaseURI}/exercises?module=reader`
  }

  getModulesSolver() {
    return `${this.BaseURI}/exercises?module=solver`
  }


  userProgressTracksSubs() {
    return `${this.BaseURI}/userprogress/tracksSubscribed`
  }

  userExerciseCompleted() {
    return `${this.BaseURI}/userprogress/exerciseCompleted?tracks=C&userId=1`
  }

  getExercises() {
    return `${this.BaseURI}/userprogress/exercises`
  }

  getDateEnrolled() {
    return `${this.BaseURI}/userprogress/dateEnrolled?tracks=C&userId=1`
  }

  getModuleProgress() {
    return `${this.BaseURI}/userprogress/moduleProgress?tracks=C&userId=1`
  }

  // getModuleProgress() {
  //   return `${this.BaseURI}/userprogress/moduleProgress?tracks=C&userId=1`
  // }

  getCode() {
    return `${this.BaseURI}/code?exercise=`
  }

}

export default ApiConfig;
