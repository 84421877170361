import { useEffect, useState } from "react"
import { postData } from "../ApiUtils/postData"
import { fetchData } from "../ApiUtils/fetchData"
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession"
import { validTillTimeString } from "../Schedule/scheduleutils"


const useScheduleTest = () => {
  const [schloading, setLoading] = useState(false);
  const [schMessage, setSchMessage] = useState('');
  const [schStatus, setSchStatus] = useState('');

  const ScheduleTest = async (testid, scheduletime, markingscheme, showresult, validtill, useridArr, title, endDateValue, endTime, isEndDateChanged, isEndTimeChanged) => {
    console.log("testid", testid)
    console.log("scheduletime", scheduletime)
    console.log("markingscheme", markingscheme)
    console.log("showresult", showresult)
    console.log("validTill", endDateValue)
    console.log("useridArr", useridArr)
    console.log("title",title)
    try {
      if (endTime !== null && endDateValue !== null ) {
       let  [combinedISOString, validTillISOString] = validTillTimeString(endTime, endDateValue);
        validtill=(isEndDateChanged || isEndTimeChanged)?combinedISOString : validTillISOString
        console.log('validTillISOString',validtill)
      }
      const userid = getValueFromSession('username')
      let postdata = { testid, userid, scheduletime, markingscheme, showresult, validtill, useridArr , title}
      const uri = `scheduletest`;
      setLoading(true)
      const data = await postData(uri, postdata);
      setSchMessage(data?.data?.message)
      setSchStatus(data?.status)
      setLoading(false)
      // console.log("response: ", data);
    } catch (error) {
      console.log("error", error.message)
    }
  }

  return { ScheduleTest, schloading, schMessage, schStatus }
}


export const useGetScheduleUsersList = (scheduleid) => {
  const [scheduleUsers, setScheduleUsers] = useState([]);
  const [isLoading,setIsLoading]=useState(false)
  const getScheduleUsers = async () => {
    console.log("first")
    setIsLoading(true)
    try {
      const uri = `getscheduleuserlist?scheduleid=${scheduleid}`;
      const data = await fetchData(uri);
      console.log("scheduleUsers: ", data);
      setScheduleUsers(data.data)
    } catch (error) {
      console.log("error", error.message)
    }finally{
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getScheduleUsers()
  }, []);
  return { scheduleUsers ,setScheduleUsers, isLoading}
}
export default useScheduleTest