import React, { useEffect, useState } from "react";
// import libraries
import { Link } from "react-router-dom";
import { toggleRowSelection } from "../managetestutils";
import { FcFolder } from "react-icons/fc";
import { Tooltip } from "react-tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import './TestBundle.scss'

export default function ShowFloderIcon({isInBundle,index,title, bundleDetails}){
  const [open,setOpen]=useState(false)

  return isInBundle && <>
         <Tooltip
          style={{ zIndex: 1 }}
          anchorSelect={`#folder${index}`}
          content={`This test prsent in the bundle ${title}`}
          place="top"
        />
        <ShowBundleDetails
          open={open}
          setOpen={setOpen}
          bundleDetails={bundleDetails}
        
        />
          <FcFolder id={'folder'+index} onClick={()=>{setOpen(true)}} />
  </>
}

export function ShowBundleDetails({open, setOpen, bundleDetails}){

    bundleDetails=bundleDetails||[]
    bundleDetails=bundleDetails[0]
 const handleClose=()=>{
      setOpen(false)
  }
  return <>
  
  <Dialog
          open={open}
          maxWidth={"lg"}
          PaperProps={{
            style: {
              borderRadius: 14,
              minHeight: "30vh",
              maxHeight: "fit-content",
              minWidth: "30%",
              width: "40%",
              // height: "100%",
              overflow: "hidden",
              position: "absolute",
              // zIndex: 10000,
              padding:0
            },
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "6px",
            }}
          >
            <span style={{ marginLeft: "1.5rem" }}>
              Test Bundle Details 
            </span>
            <IconButton>
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </DialogTitle>
          {/* <div className="hint-div"> */}

          <DialogContent style={{ overflow:"hidden"}}>
 
          
            <div className="bundle-info">
                Bundle name: <p>{bundleDetails.title}</p>
            </div>
           <div className="bundle-info">
                Track: <p>{bundleDetails.track}</p>
            </div>
           <div className="bundle-info">
                No. Assessments: <p>{bundleDetails.no_of_assessments}</p>
            </div>
     
          </DialogContent>

          <DialogActions sx={{ marginRight: "1.3rem" }}>
            

          
            <div>

            <button
              className="button "
              // style={{ marginBottom: "1rem" }}
              onClick={handleClose}
              >
              Close
            </button>
                </div>
             
          </DialogActions>
          {/* </div> */}
        </Dialog>
  </>
}