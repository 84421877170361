import React, { useMemo } from "react";
import Checkbox from "./checkbox";
import { useState } from "react";
import { useEffect,useRef } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import {FaArrowUp,FaArrowDown} from 'react-icons/fa'
import "../../assets/styles/_Scss/_table.scss";

export default function Table({ rows, headers, setSelected, isLoading,data,isCheckBox,isEditable ,setDuplicateFileName,onChangeInput,setSelectedTaskID,selectedTaskID,isOnlyOneSelect, noDataMsg, onClick, selectLable,maxHeight}) {
  let usergroup = sessionStorage.getItem("usergroup");
  if(selectLable ===undefined){
    selectLable='userid'
  }
  const [open, setOpen] = useState(false);
  const [alert, setalert] = useState(false);
  const [type, settype] = useState("");
  const [msg, setmsg] = useState("");
  const [newjson, setnewJson] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // const [data, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newdata1, setnewData1] = useState([]);
  const [isShowCheckBox,setIsShowCheckBox]=useState(true)
  // setnewData1(rows)
  const rerender=useRef(true)
  if(rerender.current && rows?.length>0){
    // console.log('use ref #######',rerender.current)
    // setnewData1(rows)
    rerender.current=false
  }
  const [infirstname, setinfirstname] = useState({
    update: false,
    index: null,
  });
  const [inlastname, setinlastname] = useState({ update: false, index: null });
  const [inMobile, setinMobile] = useState({ update: false, index: null });
  // console.log("itesms", inMobile.update, "index", inMobile.index);
 const [sorted,setSorted]=useState({sorted:'id',reversed:true})
  const [label, setLabel] = useState([]);
  // console.log('data ########',headers)
  const override = {
    display: "inline",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "5rem",
    zIndex:'11'

  };



  //   setLabel(Object.keys(headers[0]));
  //   //  console.log('label ####',label,headers)
  //   // setSelected(rows)
  //   return 
  //   <table>
  //   {/* </thead> */}

  //   {headers.map((item, index) => (
  //     <thead>
  //       {/* <tr> */}

  //       {index == 0 && (
  //         <tr>
  //           <th>#</th>
  //           <th>
  //             {" "}
  //             <Checkbox
  //               type="checkbox"
  //               name="selectAll"
  //               id="selectAll"
  //               handleClick={handleSelectAll}
  //               isChecked={isCheckAll}
  //               //  disabled1={handeledisable(item)}
  //             />
  //           </th>
  //           {/* {console.log('headres ',item)} */}
  //           {/* {item[label[1]]}    */}
  //           {headers.map((name) => (
  //             <th>{name.headerName}</th>
  //           ))}
  //           {/* {item}    */}

  //           {/* </tr> */}
  //         </tr>
  //       )}
  //     </thead>
  //   ))}

  //   <tbody>
  //     {rows.map((item, index) => (
  //       <tr>
  //         <td>{index + 1}</td>
  //         <td>
  //           <Checkbox
  //             type="checkbox"
  //             name={item.id}
  //             index={index}
  //             id={item.id}
  //             style={{ width: "1rem", height: "1rem" }}
  //             handleClick={(e) => handlecheckbox(e, item)}
  //             // isChecked={()=>{checkBox1(item.id)}}
  //             isChecked={data.includes(item)}

  //             //   disabled1={handeledisable(item, index)}
  //           />
  //           {/* {console.log('is checked  ######',data.includes(item))} */}
  //         </td>
  //         {/* {console.log('rows ####',item[headers[index%headers.length].field],headers[index%headers.length].field,[label[0]])} */}
  //         {headers.map((items) => (
  //           <td>{item[items.field]}</td>
  //         ))}
  //       </tr>
  //     ))}
  //   </tbody>
  // </table>
  // }, [data,isLoading,rows]);
function handleSelectAll (e)  {
    setIsCheckAll(!isCheckAll);

    setSelected(
      rows.map((li) => {
        if (li !== undefined) {
          // console.log("li",li)
          return li;
        }
      })
    );
    if (isCheckAll) {
      setSelected([]);
    }
    // setSelected(data);
    console.log("data", data);
  };

function handlecheckbox (e, items)  {
    const { id, checked,tagName } = e.target;
    if(isOnlyOneSelect){
      setSelected([items])
    }
    else if(checked && !data.includes(items))
    {
      
      setSelected([...data, items]);
    }
    else{
      // console.log('e.id ############',e.taskid !== items.id,e.id,items.id)
      setSelected(data.filter((e) =>  e[selectLable] !== items[selectLable]));
    }
  // console.log(data)
  };

  // function changeSelect(elements) {
  //   var values = {};
  //   elements.forEach(function(item){
  //     values[item.id] = item.value;
  //   });
  //   elements.forEach(function(item){
  //     for(var i = 0; i < item.children.length; i++) {
  //       for(ids in values) {
  //         if(item.id != ids && item.children[i].value == values[ids]) {
  //           item.children[i].style.display = 'none';
  //         }
  //       }
  //     }
  //   });
  // }

 useEffect(()=>{
  setnewData1(rows)
  if(isCheckBox!==undefined)
{
  setIsShowCheckBox(isCheckBox)
} 
},[rerender.current,isLoading,rows,data])

const sortData = (column) => {
  setSorted({ sorted: column, reversed: !sorted.reversed });
  const newData = [...rows];
  newData.sort((a, b) => {
    const lastA = a[column];
    const lastB = b[column];

    if (sorted.reversed) {

      return lastA?.toString()?.localeCompare(lastB);
    }
  
    return lastB?.toString()?.localeCompare(lastA);

    // console.log(emailA)
    // console.log(emailB)
  });
  // setUserResponse(activeusers);
  // console.log(newData,rows)
  // rows=newData
  setnewData1(newData)

};
const renderArrow = () => {
  // console.log("sorted.reversed ", sorted.reversed)
  if (sorted.reversed) {
    return <FaArrowDown />;
  } else {
    return <FaArrowUp />;
  }
};
  function checkBox1 (userid)  {
    // console.log("userid #######", userid);
    if (isCheckAll) {
      return true;
    }
    let found = false;
    // console.log("####################");
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === userid) {
        // console.log("data[i].id===userid", data[i].id === userid);
        found = true;
        return found;
      }
    }
    return found;
  };
  function handeledisable(items, index) {
    if (items == undefined) {
      // setIndexarr(oldarray=>[...oldarray])
      return true;
    } else {
      return false;
    }
  }
  // const onChangeInput = (e, taskid) => {
  //   const { name, value } = e.target
  //   // console.log('name', name)
  //   // console.log('value', value)
  //   // console.log('taskid', taskid)

  //   const editData = newdata1.map((item) =>
  //     item.taskid === taskid && name ? { ...item, [name]: value } : item
  //   )

  //   // console.log('editData', editData)

  //   setnewData1(editData)
  // }

 const handleSelectBox=(e,selectBoxNum)=>{
    
 }
  const handleSameFileName=()=>{
    let fileNameSet=new Set()
    let duplicate=[]

    newdata1.map(item=>{
      if(!fileNameSet.has(item.fileName))
{

  fileNameSet.add(item.fileName)
} 
else{
  duplicate.push(item.fileName)
}
      
     
    })
    console.log(fileNameSet,duplicate)
    setDuplicateFileName(duplicate)
    // return false
  }
  return (
    <div style={{
      height: maxHeight && maxHeight + 'rem'
    }} className="table-container">
      {/* {(isLoading || true || newdata1.length<0) && (
       <>
        Loading... <PulseLoader
          color="#4199ce"
          loading={isLoading}
          cssOverride={override}
          size={5}
          className="pulse-loader"
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
          </> */}
      {/* )} */}
      {/* {tabel} */}
      <table>
    {/* </thead> */}

    {headers?.map((item, index) => (
      <thead>
        {/* <tr> */}

        {index == 0 && (
          <tr>
            <th>#</th>
            {isShowCheckBox &&<th>
              {" "}
              <Checkbox
                 type={isOnlyOneSelect?'radio':"checkbox"}
                //  name={isOnlyOneSelect?'file':item?.id}
                 index={index}
                 id={item?.id}
                handleClick={(e)=>{if(!isOnlyOneSelect){handleSelectAll(e)}}}
                isChecked={isOnlyOneSelect ?data.length>=1 :(isCheckAll &&  newdata1?.length>0 && data?.length>0) || (data?.length === newdata1?.length && newdata1?.length>0)}
                indeterminate = {data?.length>0}
                //  disabled1={handeledisable(item)}
              />
            </th>}
          
           
            {headers?.map((name) => (
              <th onClick={()=>{
                 if(name.isSort !== undefined && !name.isSort) return
                 else sortData(name.field)
                
                }}>{name.headerName} {sorted.sorted===name.field&& renderArrow()}</th>
            ))}
       
          </tr>
        )}
      </thead>
    ))}

    {(isLoading || newdata1?.length<0 )?
      <PulseLoader
      color="#7b1fa2"
      loading={isLoading || newdata1?.length<0}
      cssOverride={override}
      size={15}
      className="pulse-loader"
      aria-label="Loading Spinner"
      data-testid="loader"
      speedMultiplier={0.5}
    />
    :<tbody>
    {newdata1?.length >0 ?
       newdata1?.map((item, index) => (
        <tr>
          <td>{index + 1}</td>
          {isShowCheckBox && <td>
            <Checkbox
              type={isOnlyOneSelect?'radio':"checkbox"}
              name={isOnlyOneSelect?'file':item?.id}
              index={index}
              id={item?.id}
              style={{ width: "1rem", height: "1rem" }}
             
              
               handleClick={(e) =>{
                //  if(isOnlyOneSelect && data.length===0)
                //  {
                //   console.log(isOnlyOneSelect && data.length===0)
                //   handlecheckbox(e, item)
                // }
                // else{
                  handlecheckbox(e, item)
                // }
                }
              }
               
              // isChecked={()=>{checkBox1(item.id)}}
              isChecked={data?.includes(item)}

              //   disabled1={handeledisable(item, index)}
            />
            {/* {console.log('is checked  ######',data.includes(item))} */}
          </td>}
          
          {/* {console.log('rows ####',item[headers[index%headers.length].field],headers[index%headers.length].field,[label[0]])} */}
          {headers?.map((items) => (
            
           items?.isEditable?
           <td 
        //     onChange={(e)=>{
              
        //     // console.log('chnage td value ',e.target.value)
        // }}
        >
      
          {

         items?.selectTag ?
            <select 
            onChange={(e)=>{
              if(!selectedTaskID.includes(item))
              setSelectedTaskID([parseInt(e.target.value),...selectedTaskID])
            }}
            // onChange={()=>{changeSelect(item[items?.field])}}
            >
             { item[items?.field]?.filter((item)=> !selectedTaskID.includes(item),console.log(!selectedTaskID.includes(item))).map((item)=>(
              
             <option>{ item}</option>
             ))

         
             }
            </select>
         : 
          <input 
          className="td-input"
          type='text'
          name={items?.field}
          style={{
            // border:'none',
            // backgroundColor:'inherit',
            // width:"100%",
            // height:'100%'
          }} 
          onBlur={handleSameFileName}
          onChange={(e)=>{onChangeInput(e,index,setnewData1,newdata1);}} 
          // onKeyUp={handleSameFileName}
          value={item[items?.field]}/>
        }
          </td>

            : 
            <td 
            onClick={()=>{ 
              if(items.onClick !==undefined){

                items.onClick(item)}
              }}
            onChange={(e)=>{
                // console.log('chnage td value ',e.target.value)
            }}>{item[items?.field] || 'NA'}</td>

          ))}
        </tr>
      ))
      
      :
    

      <td colSpan={headers.length+1+isShowCheckBox}>

        <p className="pyyi_table-zerostate">{noDataMsg !== undefined? noDataMsg:'No data available'}</p>
      </td>
      
      }
    </tbody>}
  </table>
    </div>
  );
}
