import React, { useEffect } from 'react'
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import { AlertTitle, DialogTitle } from '@mui/material';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FaSortDown } from "react-icons/fa6";
import { FaSortUp } from "react-icons/fa6";
import { CSVLink, CSVDownload } from "react-csv";
import {LoaderUtility1} from '../../../UtilityFunctions/Loader_utility';


export default function Answerdialog({data,dialog,setDialog,userid,scheduleid,loading,setLoading}) {
console.log("inserid anse",data)
    const [sorted,setSorted]=useState({sorted:'correct',reversed:true})
    const [data1,setnewData]=useState(data)
    useEffect(()=>{
        
setnewData(data1)
    },[data])

    function downloadcsv(respData){
      const expandedRowData = respData || []; // Get expanded row data
      const headers = ['title', 'description', 'category', 'subcategory', 'module','correct','total_question','score'];
  
      // console.table(expandedRowData)
      const csvData = [
        ['userid', userid],
        ['scheduleid', scheduleid],
      
        [' ', ' '],
        headers, // Include headers
  
        ...expandedRowData.map(row => {
          const rowData = [
            // row.title,
            row.category + '-' + row.subcategoryid + '-' + row.rank,
            row.description,
            row.category,
            row.subcategoryid,
            row.module,
            row.correct1,
            row.questions,
            row.score,
          ];
          return rowData;
        })
      ];
  
      return (
        <CSVLink data={csvData} filename={"Results.csv"}>
          <button className="link-button">Download CSV</button>
        </CSVLink>
      );
    }





  
    const sortbytitle=()=>{
        setSorted({ sorted: "title", reversed: !sorted.reversed });

     let newdata=[...data1]
     newdata.sort((a,b)=>{
        let title1=a.title;
        let title2=b.title;

        if(sorted.reversed){
          return title1.localeCompare(title2)
        }
       return title2.localeCompare(title1)
     })
console.log("newdata",newdata)
    setnewData(newdata)
    }

    const sortbydescription=()=>{
        setSorted({ sorted: "description", reversed: !sorted.reversed });

        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.description;
           let title2=b.description;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        })
        setnewData(newdata)

    }
    
    const sortbycategory=()=>{
        setSorted({ sorted: "category", reversed: !sorted.reversed });

 let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.category;
           let title2=b.category;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        })
        setnewData(newdata)

    }

    const sortbysubcategory=()=>{
        setSorted({ sorted: "subcategory", reversed: !sorted.reversed });

        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.subcategoryid;
           let title2=b.subcategoryid;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        })
        setnewData(newdata)

    }

    const sortbymodule=()=>{
        setSorted({ sorted: "module", reversed: !sorted.reversed });

        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.module;
           let title2=b.module;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        })
        setnewData(newdata)

    }

    const sortbycorrect=()=>{
        setSorted({ sorted: "correct", reversed: !sorted.reversed });

        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.correct1;
           let title2=b.correct1;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        }) 

        console.log("DATA",data,"NEw data",newdata)
        setnewData(newdata)
  
    }

    

    const sortbytotal_questions=()=>{
        setSorted({ sorted: "questions", reversed: !sorted.reversed });
        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=a.questions;
           let title2=b.questions;
   
           if(sorted.reversed){
             return title1.localeCompare(title2)
           }
          return title2.localeCompare(title1)
        })
        setnewData(newdata)

    }

    const sortbyscore=()=>{
        setSorted({ sorted: "score", reversed: !sorted.reversed });
        let newdata=[...data1]
        newdata.sort((a,b)=>{
           let title1=handlescore(a);
           let title2=handlescore(b);
   
           if(sorted.reversed){
             return title1-(title2)
           }
          return title2-(title1)
        })
        setnewData(newdata)
    }

    const sortbymaxscore=()=>{
      setSorted({ sorted: "maxscore", reversed: !sorted.reversed });
      let newdata=[...data1]
      newdata.sort((a,b)=>{
         let title1=handlemaxscore(a);
         let title2=handlemaxscore(b);
 
         if(sorted.reversed){
           return title1 - (title2)
         }
        return title2 - (title1)
      })
      setnewData(newdata)
  }


  const sortbyincorrect = () => {
    setSorted({ sorted: "incorrect", reversed: !sorted.reversed });

    let newdata = [...data1];
    newdata.sort((a, b) => {
        // Calculate incorrect answers for both rows
       
        const incorrectA = parseInt(JSON.parse(a?.attempted)-a.correct1 || 0)
        const incorrectB = parseInt(JSON.parse(b?.attempted)-b.correct1 || 0)
        console.log("incorect",incorrectA,"incorrectB",incorrectB)

        // Compare based on the sorting order
        if (sorted.reversed) {
            return incorrectA - incorrectB; // Ascending
        } else {
            return incorrectB - incorrectA; // Descending
        }
    });

    setnewData(newdata);
};

  const sortbynotattempt=()=>{
    setSorted({ sorted: "notattempted", reversed: !sorted.reversed });

    let newdata = [...data1];
    newdata.sort((a, b) => {
        const notAttemptedA = parseInt(JSON.parse(a?.notattempted) ||0)
        const notAttemptedB = parseInt(JSON.parse(b?.notattempted) ||0)

        if (sorted.reversed) {
            return notAttemptedA - notAttemptedB; 
        } else {
            return notAttemptedB - notAttemptedA; 
        }
    });
    setnewData(newdata)

}
function getMaxScore(level) {
  let totalmarks = 0.0;
  if (level >= 1 && level <= 3) {
    totalmarks = 10.0;
  } else if (level >= 4 && level <= 6) {
    totalmarks = 20.0;
  } else if (level >= 7 && level <= 10) {
    totalmarks = 30.0;
  } else {
    totalmarks = 40.0;
  }
  return totalmarks;
}


function handlemaxscore(item){
  let maxscore=0
  let no_quetions=parseInt(item?.questions) || 0
  switch(item.markingscheme){
    case 'pravidefault':
                       maxscore = getMaxScore(item.level);
                       break;
    case 'marking scheme(+2, -1)':
                               maxscore =no_quetions *2;
                               break;

    case 'marking scheme(+4, -0)':
                                maxscore=no_quetions *4
                                break;

    case 'marking scheme(+4, -2)':
                               maxscore=no_quetions*4
                               break;


    case 'marking scheme(+1, -0)':
                               maxscore=no_quetions *1
                               break;


    case 'marking scheme(+4, -1)':
                                maxscore=no_quetions*4
                                break;



  }
  return maxscore;

}

function handlescore(item){
  let score=0;
  let incorrect=(item.attempted || item.questions)-item.correct1
  let correct=item.correct1
  console.log("markingscheme",item.markingscheme);
  switch(item.markingscheme){
    case 'pravidefault':
                       score = parseFloat(Math.ceil(item.correct1 * (getMaxScore(item.level) / item.questions)));
                      
                       break;
    case 'marking scheme(+2, -1)':
                               score=((correct * 2)- (incorrect* 1))
                               console.log("marking scheme(+2, -1)",score,"correct",correct,"incorrect",incorrect)
                               break;

    case 'marking scheme(+4, -0)':
                                score=((correct* 4)- (incorrect* 0))
                                break;

    case 'marking scheme(+4, -2)':
                               score=((correct * 4)- (incorrect* 2))
                               break;


    case 'marking scheme(+1, -0)':
                               score=((correct * 1)- (incorrect* 0))
                               break;


    case 'marking scheme(+4, -1)':
                                score=((correct * 4)- (incorrect* 1))
                                break;



  }
  return score;
}

    function renderSortIcon() {
        if (sorted.reversed) {
          // console.log("sorted1.reversed ", sorted1.reversed)
          return <FaSortDown />;
        } else {
          return <FaSortUp />;
        }
      }
  return(<>

  
<Dialog open={dialog} maxWidth='lg' PaperProps={{
      style: {
        overflow: 'hidden', // Disable scrollbar
      },
    }} >
          <DialogTitle>
            Score breakdown: Schedule Id - {scheduleid}
          </DialogTitle>
          <DialogContent>
           {loading?
           <LoaderUtility1 isLoading={loading} ></LoaderUtility1>
          
           :
           data1?.length>0 ?
            <section style={{height:'22rem',overflowY:'auto'}}>
           <table>
              <thead>
                  <tr>
                  <th>#</th>
                  <th onClick={()=>{sortbytitle()}}>
                      <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                        <label>Title</label>
                        {sorted.sorted=='title' && renderSortIcon()}
                      </span>                        
                      </th>
                  <th onClick={()=>{sortbydescription()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Description</label>  
                   {sorted.sorted=='description' && renderSortIcon()}
                   </span>
                 </th>
                  <th onClick={()=>{sortbycategory()}}>
                      
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Category</label>  
                   {sorted.sorted=='category' && renderSortIcon()}
                   </span> 
                      
                      
                      </th>
                  <th onClick={()=>{sortbysubcategory()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Subcategory</label>  
                   {sorted.sorted=='subcategory' && renderSortIcon()}
                   </span>  
                  </th>

                  <th onClick={()=>{sortbymodule()}}>
                           <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Module</label>  
                   {sorted.sorted=='module' && renderSortIcon()}
                   </span> 
                      
                      
                  </th>
                  <th onClick={()=>{sortbycorrect()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Correct</label>  
                   {sorted.sorted=='correct' && renderSortIcon()}
                   </span>     
                  </th>
                  {/* <th onClick={()=>{sortbyincorrect()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Incorrect</label>  
                   {sorted.sorted=='incorrect' && renderSortIcon()}
                   </span> 
                       
                  </th> */}
                
                  {/* <th onClick={()=>{sortbynotattempt()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Not attempted</label>  
                   {sorted.sorted=='notattempted' && renderSortIcon()}
                   </span> 
                      
                  </th> */}
                  <th onClick={()=>{sortbytotal_questions()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Total questions</label>  
                   {sorted.sorted=='questions' && renderSortIcon()}
                   </span> 
                  </th>
                  {/* <th onClick={()=>{sortbyscore()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Score</label>  
                   {sorted.sorted=='score' && renderSortIcon()}
                   </span>  
                  </th>
                  <th onClick={()=>{sortbymaxscore()}}>
                  <span className='input-search' style={{display:'flex',gap:'0.5rem'}}>
                   <label>Max score</label>  
                   {sorted.sorted=='maxscore' && renderSortIcon()}
                   </span>  
                  </th> */}

                  </tr>
              </thead>
              <tbody>
                  {data1?.map((item,index)=>(
                      <tr>
                      <td>{'EX' + (index+1)}</td>
                      <td>{item.category}-{item.subcategoryid}-{item.rank}</td>
                      <td>{item.description}</td>
                      <td>{item.category}</td>
                      <td>{item.subcategoryid}</td>
                      <td>{item.module}</td>
                      <td>{item.correct1}</td>
                      {/* <td>{JSON.parse(item?.attempted)-item.correct1 || 0}</td>
                      <td>{JSON.parse(item?.notattempted) ||0}</td> */}
                      <td>{item.questions}</td>
                      {/* <td>{handlescore(item)}</td>
                      <td>{handlemaxscore(item)}</td> */}
                    
                      </tr>
                  ))}
              </tbody>
           </table>
          </section>
           :
           'No result to display'
           }   
      
          </DialogContent>
          <DialogActions sx={{ marginLeft: "20px" }}>
            <div style={{display:'flex',flexDirection:'row-reverse',gap:'1rem'}}>
            <button
              className="button primary"
              style={{ marginBottom: "1rem", marginRight: "1rem" }}
              onClick={()=>{setDialog(false)}}
            >
              Close
            </button>
            {/* {downloadcsv(data1)} */}
            </div>
          </DialogActions>
        
      </Dialog>
  
  
  
  </>)

}