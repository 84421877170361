import { useState, useEffect, useRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import Checkbox from "../UtilityFunctions/checkbox";
import ApiConfig from "../../ApiConfig";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { LoaderUtility1 } from "../UtilityFunctions/Loader_utility";
import axios from "axios";
import { CgAsterisk } from "react-icons/cg";
import Collapse from "@mui/material/Collapse";
import ModalComponent from "../Modals/Modal_component";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FiUpload } from "react-icons/fi";
import DialogAdd from "./DialogAdd";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import "../ManageSchedule/TableData/tabledata.scss";

import "./createtest.scss";

function Createtest() {
  const [evarray, setEvarray] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheck2, setIsCheck2] = useState([]);
  const [categoryarr, setCategoryarr] = useState([]);
  const [subcateoryarr, setSubcategoryarr] = useState([]);
  const [category, setCategory] = useState(["ALL"]);
  const [subcategory, setSubcategory] = useState(["ALL"]);
  const [exercise, setExecise] = useState(5);
  const [module, setModule] = useState("reader");
  const [league, setLevel] = useState("Beginner");
  const [modal, setModal] = useState(false);
  const [msg, setMessage] = useState("");
  const [duration, setDuration] = useState(0);
  const [description, setdescription] = useState("");
  const [questionsarr, setQuestionsarr] = useState();
  const [ename, setEname] = useState("");
  const [lvarray, setlvarray] = useState([]);
  // const [isChecked, setisChecked] = useState([])
  const [languagearr, setLanguagearr] = useState([]);
  const [track, setTrack] = useState({ track: "C", max: "25" });
  const [collaspse, setcollapse] = useState(false);
  const [next, setNext] = useState(1);
  const [loading, setIsLoading] = useState(false);
  const [submit_smg, setSubmitmsg] = useState(false);
  const [status, setstatus] = useState(200);
  const [navigate, setNaviagate] = useState(false);
  const [bordercolor1, setBordercolor] = useState(null);
  const [visibility, setVisibility] = useState("private");
  let toastId = useRef(null);
  let navigate1 = useNavigate();
  const [open, setOpen] = useState(false);
  let Api = new ApiConfig();
  let muiobject = {
    option: {
      color: "black",
    },
    chips: {
      background: "#736e6e",
      borderRadius: "5%",
    },
    maxHeight: "190px",
  };
  // ('beginner', 'beginner-intermediate', 'intermediate', 'intermediate-advanced', 'advanced', 'all');

  let Difficultyarr = [
    "Beginner",
    "Beginner-intermediate",
    "Intermediate",
    "Intermediate-advanced",
    "Advanced",
    "CUSTOM",
    "All",
  ];

  const fetchCategorys = async () => {
    return axios
      .get(
        Api.addApiKey(`${Api.BaseURI}/getallcategory?language=${track.track}`)
      )
      .then((res) => setCategoryarr(res.data));
  };

  const handleTrack = async () => {
    let group_name = getValueFromSession('group_name');
    return axios
      .get(Api.addApiKey(`${Api.BaseURI}/getalltrack?group_name=${group_name}`))
      .then((res) => {
        setLanguagearr(res.data.result);
      });
  };

  const fetchsubcategorys = async (category) => {
    setCategory(category);
    return axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/getallSubcategory?category=${category}&track=${track.track}`
        )
      )
      .then((res) => setSubcategoryarr(res.data));
  };

  useEffect(() => {
    fetchCategorys(track.track);
    if (languagearr.length == 0) {
      handleTrack();
    }
    setSubcategory(["ALL"]);
    setCategory(["ALL"]);
    setSubcategoryarr([]);
  }, [track]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(questionsarr.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handlecheckbox = (e, item) => {
    const { id, checked } = e.target;

    if (checked) {
      setIsCheck([...isCheck, item]);
    } else {
      setIsCheck(isCheck.filter((e) => e !== item));
    }
  };

  const GetQuesitions = async () => {
    setIsLoading(true);
    console.log("subcategory", subcategory);
    if (league == "CUSTOM") {
      console.log("lv", lvarray);
      await axios(
        Api.addApiKey(
          `${Api.BaseURI}/getCustomexercise?lvarray=${JSON.stringify(
            lvarray
          )}&subcategory=${subcategory}&total=${exercise}&language=${
            track.track
          }&module=${module}`
        )
      )
        .then((res) => {
          console.log("quetions$$33", res.data.result);
          setQuestionsarr(res.data.result);
        })
        .then(() => setIsCheck([]));
    } else {
      let { min, max } = getLevelRange(league, track.max, track.track);

      await axios(
        Api.addApiKey(
          `${Api.BaseURI}/getRandomExercisesSubcategoryadmin?minlevel=${min}&maxlevel=${max}&subcategory=${subcategory}&total=${exercise}&language=${track.track}&module=${module}`
        )
      )
        .then((res) => {
          console.log("quetions$$", res.data.result);
          setQuestionsarr(res.data.result);
        })
        .then(() => setIsCheck([]));
    }
    setIsLoading(false);
  };

  function handleMiddlenext() {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
    setEvarray(evarray.concat(isCheck));

    const sumOfLevels = isCheck.reduce((acc, item) => {
      return acc + item.level;
    }, 0);

    let duration =
      isCheck.length > 0
        ? parseInt((sumOfLevels / isCheck.length) * isCheck.length)
        : 0;
    setDuration(duration);
    setNext((next) => next + 1);
  }

  function getLevelRange(league, maxLevel1, track) {
    let maxLevel = track == "C" ? 15 : maxLevel1;
    // console.log("league", league, "maxlevel", maxLevel,"trac",track);

    const rangeSize = Math.ceil(maxLevel / 3);

    switch (league) {
      case "Beginner":
        return { min: 1, max: rangeSize };
      case "Beginner-intermediate":
        return { min: 1, max: 2 * rangeSize };
      case "Intermediate":
        return { min: rangeSize + 1, max: 2 * rangeSize };
      case "Intermediate-advanced":
        return { min: rangeSize + 1, max: maxLevel };
      case "Advanced":
        return { min: 2 * rangeSize + 1, max: maxLevel };
      case "All":
        return { min: 1, max: maxLevel };
      default:
        return { min: -1, max: -1 };
    }
  }
  function getMaxScore(level) {
    let message;
    let code;
    let totalmarks = 0.0;
    if (level >= 1 && level <= 3) {
      totalmarks = 10.0;
    } else if (level >= 4 && level <= 6) {
      totalmarks = 20.0;
    } else if (level >= 7 && level <= 10) {
      totalmarks = 30.0;
    } else {
      totalmarks = 40.0;
    }
    return totalmarks;
  }

  function GetTotalMarks(selectedexids) {
    let totalscore = 0;
    for (let i = 0; i < selectedexids.length; i++) {
      totalscore += getMaxScore(selectedexids[i].level);
    }
    return totalscore;
  }

  function Getexidarr(selectedexids) {
    let exidarr = [];
    for (let i = 0; i < selectedexids.length; i++) {
      exidarr.push({
        exid: selectedexids[i].exid,
        marks: getMaxScore(selectedexids[i].level),
      });
    }
    return exidarr;
  }
  function getCategorys_from_selected(isCheck) {
    let categoryarr = [];
    if (isCheck) {
      categoryarr = [...new Set(isCheck.map((item) => item.category))];
      return categoryarr;
    }
    return categoryarr;
  }
  const createTest = async (track, ename) => {
    // let userid = "debug1@gmail.com";
    let userid = getValueFromSession("username");
    console.log("userid", userid);
    let totalmarks = GetTotalMarks(isCheck);
    let exidArr = Getexidarr(isCheck);

    const sumOfLevels = isCheck.reduce((acc, item) => {
      return acc + item.level;
    }, 0);

    const sumOfQuestions = isCheck.reduce((acc, item) => {
      return acc + parseInt(item.number_questions);
    }, 0);
    console.log("sum of Quetions", sumOfQuestions);

    let avglevel = parseInt(sumOfLevels / isCheck.length);
    let categorydetails = {};

    categorydetails["category"] = getCategorys_from_selected(isCheck);

    categorydetails = JSON.stringify(categorydetails);

    if (isCheck && isCheck.length <= 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select at least one exercise.", {
          position: "top-center",
        });
      }
      return;
    }
    if (ename?.length <= 0) {
      setBordercolor("ename");
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter a test name", {
          position: "top-center",
        });
      }
      return;
    }
    if (!duration || duration == 0) {
      setBordercolor("duration");
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter a duration for the test.", {
          position: "top-center",
        });
      }
      return;
    } else {
      setModal(true);

      try {
        setSubmitmsg(true);
        let data = {
          userid,
          name: ename,
          track,
          duration,
          totalmarks,
          avglevel,
          league,
          visibility: visibility,
          noofexids: isCheck?.length,
          description,
          exidArr,
          categorydetails,
          sumOfQuestions,
        };
        const res = await axios.post(
          Api.addApiKey(`${Api.BaseURI}/createtest/`),
          data
        );
        if (res.data.status == 201) {
          setIsLoading(false);
          setstatus(201);
          setMessage("Test created successfully.");
        } else {
          setIsLoading(false);
          setstatus(200);
          setMessage(`Test is not created: ${res.data.message}`);
        }
        setSubmitmsg(false);
      } catch (error) {
        console.log("error", error);
        setMessage("Oops something wrong! please try again.");
      }
    }
  };

  function setevarray(value, item) {
    let { min, max } = getLevelRange(item, track.max, track.track);
    let obj = { id: item, minlevel: min, maxlevel: max, count: value };
    const index = lvarray.findIndex((item) => item.id === obj.id);

    if (index !== -1) {
      lvarray[index] = obj;
    } else {
      lvarray.push(obj);
    }
    setlvarray(lvarray);
    setExecise(lvarray.reduce((sum, item) => sum + parseInt(item.count), 0));
    // console.log("lavarray",lvarray)
  }

  function Firststep() {
    return (
      <>
        <div className="assement-header">
          <label>Step 1</label>
          <button
            onClick={(e) => {
              navigate1(-1);
            }}
            className="button link-button"
          >
            Cancel creation
          </button>
        </div>

        <div className="assement-main">
          <div className="asseement-row1">
            {/* <div className="assement_track">
              <label for="level">Test /Name </label>
              <input
                type="text"
                value={ename}
                style={{ padding: "0.49rem" }}
                onChange={(e) => setEname(e.target.value)}
                class="evalip"
                name="evalname"
                placeholder="test name"
                required=""
                autofocus=""
              />
            </div> */}
            <div className="assement_track">
              <label for="language"> Test Track </label>
              <select
                value={JSON.stringify(track)}
                onChange={(e) => {
                  setTrack(JSON.parse(e.target.value));
                }}
              >
                <option value={JSON.stringify(track)}>{track.track}</option>
                {languagearr.map((item, index) => {
                  if (item.track !== track.track) {
                    return (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.track}
                      </option>
                    );
                  }
                  return null;
                })}
              </select>
            </div>
            <div className="assement_track">
              <label for="language">Difficulty Level</label>
              <select value={league} onChange={(e) => setLevel(e.target.value)}>
                {process.env.REACT_APP_SHOW_CREATE_CUSTOM_EXERCISES == "true"
                  ? Difficultyarr.map((item) => (
                      <option value={item}>{item}</option>
                    ))
                  : Difficultyarr.filter((item) => item != "CUSTOM").map(
                      (item) => <option value={item}>{item}</option>
                    )}
              </select>
            </div>
          </div>
          <div className="assement-row1">
            <div className="assement_track">
              {league == "CUSTOM" &&
                Difficultyarr.filter(
                  (item) =>
                    item != "All" &&
                    item != "CUSTOM" &&
                    item !== "Intermediate-advanced" &&
                    item !== "Beginner-intermediate"
                ).map((item) => (
                  <>
                    <label>{item}</label>
                    <input
                      className="input"
                      onChange={(e) => setevarray(e.target.value, item)}
                      placeholder="Enter the number of exercises"
                    ></input>
                  </>
                ))}
            </div>
            <div className="assement_track">
              <label for="level">Category </label>
              <Multiselect
                isObject={false}
                value={category}
                selectedValues={category}
                options={
                  categoryarr && categoryarr.length > 0
                    ? ["ALL", ...categoryarr.map((item) => item.category)]
                    : ["ALL"] // Or provide some default options
                }
                showCheckbox
                onSelect={fetchsubcategorys}
                onRemove={fetchsubcategorys}
                style={muiobject}
              />
            </div>
            <div className="assement_track">
              <label for="subcategory">Sub-category</label>
              <Multiselect
                isObject={false}
                value={subcategory}
                selectedValues={subcategory}
                options={[
                  "ALL",
                  ...subcateoryarr.map((item) => item.subcategoryid),
                ]}
                showCheckbox
                onSelect={(e) => {
                  setSubcategory(e);
                }}
                onRemove={(e) => {
                  setSubcategory(e);
                }}
                style={muiobject}
              />
            </div>
            <div className="assement_track">
              <label for="exercise">No of Exercises </label>
              <input
                className="input"
                placeholder="Enter the number of exercises"
                onChange={(e) => setExecise(e.target.value)}
                value={exercise}
              ></input>
            </div>
          </div>
        </div>

        <div className="assement-footer"></div>
        <div className="buttons assement_fbuttons">
          <button
            className="button  primary"
            onClick={() => {
              setNext((next) => next + 1);
              GetQuesitions();
            }}
          >
            Next
          </button>
        </div>
      </>
    );
  }
  function Secondstep() {
    return (
      <>
        <div className="assement-header">
          <label>Step 2</label>
          <div className="assement-header-inner">
            <span>
              Total questions :{" "}
              {isCheck.reduce(
                (sum, item) => sum + parseInt(item.number_questions),
                0
              )}
            </span>
            |
            <span>
              Estimated duration:{" "}
              {(isCheck.reduce((sum, item) => sum + item.level, 0) /
                isCheck.length) *
                isCheck.length || 0}{" "}
              minutes
            </span>
            {process.env.REACT_APP_SHOW_CUSTOMADD == "true" && (
              <button
                className="button small secondary"
                onClick={(e) => setOpen(true)}
              >
                Add exercises
              </button>
            )}
            <button
              onClick={(e) => {
                navigate1(-1);
              }}
              className="button link-button"
            >
              Cancel creation
            </button>
          </div>
        </div>
        <div className="assement-main">
          <div
            className="table-container display_data"
            // style={{ maxHeight: "19rem" }}
          >
            <table>
              <thead>
                <th>
                  {" "}
                  <Checkbox
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </th>
                {process.env.REACT_APP_SHOW_EXID_CREATE == "true" ? (
                  <th>Exid</th>
                ) : (
                  <th>#</th>
                )}

                <th>Description</th>
                <th className="alignCenter">Category</th>
                <th className="alignCenter">Sub-category</th>
                <th className="alignCenter">Level</th>
                <th className="alignCenter">Questions</th>
                {/* <th>operation</th> */}
              </thead>
              <tbody>
                {questionsarr?.map((item, index) => (
                  <tr>
                    <td>
                      {" "}
                      <Checkbox
                        type="checkbox"
                        name={item.exid}
                        id={item.exid}
                        handleClick={(e) => handlecheckbox(e, item)}
                        isChecked={isCheck.includes(item)}
                      />
                    </td>
                    {process.env.REACT_APP_SHOW_EXID_CREATE == "true" ? (
                      <td>{item.exid}</td>
                    ) : (
                      <td>{index + 1}</td>
                    )}

                    <td>{item.description}</td>
                    <td className="alignCenter">{item.category}</td>
                    <td className="alignCenter">{item.subcategoryid}</td>
                    <td className="alignCenter">{item.level}</td>
                    <td className="alignCenter">{item.number_questions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="assement_footer"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div className="assement_fbuttons">
            <button
              className="button link-button "
              onClick={() => {
                setNext((next) => next - 1);
                setIsCheckAll(false);
                setlvarray([]);
              }}
            >
              Back
            </button>

            <button
              className="button  primary"
              onClick={() => {
                handleMiddlenext();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }

  function Thirdsstep() {
    return (
      <>
        <div className="assement-header">
          <label>Step 3</label>
          <button
            onClick={(e) => {
              navigate1(-1);
            }}
            className="button link-button"
          >
            Cancel creation
          </button>
        </div>
        <div className="assement-main">
          <label>
            Test Name
            <CgAsterisk style={{ color: "red" }} />
          </label>
          <input
            className="input"
            value={ename}
            style={{ borderColor: bordercolor1 === "ename" ? "red" : "" }}
            onChange={(e) => {
              e.preventDefault();
              setEname(e.target.value);
            }}
          />
          <label>
            Duration
            <CgAsterisk style={{ color: "red" }} />
          </label>
          <input
            style={{ borderColor: bordercolor1 === "duration" ? "red" : "" }}
            className="input"
            type="number"
            value={duration}
            onChange={(e) => setDuration(Math.round(e.target.value) || "")}
          ></input>

          <label>Description </label>
          <input
            className="input"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          ></input>
          {/* <label>Visibility</label> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              margin: "0.2rem",
            }}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                setVisibility(e.target.checked ? "private" : "public");
              }}
              defaultChecked={true}
            ></input>
            <label>Mark this template as private. </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#fbfafc",
            }}
            onClick={() => setcollapse(!collaspse)}
          >
            <label>{`Exercises (${isCheck.length})`}</label>
            <span style={{ marginTop: "0.3rem" }}>
              {collaspse ? (
                <MdOutlineKeyboardArrowUp size={30} />
              ) : (
                <MdKeyboardArrowDown size={30} />
              )}
            </span>
          </div>
          <Collapse in={collaspse}>
            <div
              className="table-container display_data"
              style={{ height: "11rem" }}
            >
              <table>
                <thead>
                  {/* <th>
                    {" "}
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  </th> */}

                  <th>Exercises</th>
                </thead>
                <tbody>
                  {isCheck.length > 0 ? (
                    isCheck?.map((item) => (
                      <tr>
                        {/* <td>
                        {" "}
                        <Checkbox
                          type="checkbox"
                          name={item.exid}
                          id={item.exid}
                          handleClick={(e) => handlecheckbox(e, item)}
                          isChecked={isCheck.includes(item)}
                        />
                      </td> */}

                        <td>{item.description}</td>
                      </tr>
                    ))
                  ) : (
                    <td colspan="8">
                      <div className="pyyi_table-zerostate">
                        No Exercises found!
                      </div>
                    </td>
                  )}
                </tbody>{" "}
              </table>
            </div>
          </Collapse>
        </div>
        <div className="assement_footer">
          <div className="assement_fbuttons">
            <button
              className="button link-button"
              onClick={() => setNext((next) => next - 1)}
            >
              Back
            </button>

            <button
              className="button  primary"
              onClick={() => {
                createTest(track.track, ename);
              }}
            >
              Create
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <ToastContainer />

      <div
        className="assement-wrapper"
        style={{ display: `${loading ? "none" : ""}`, top: "52%" }}
      >
        <header className="page_header">
          <h2 className="title">Create Test </h2>
        </header>
        {/* <button onClick={(e)=>{navigate1(-1)}} className="">Cancel Creation</button> */}

        {next == 1 && Firststep()}
        {next == 2 && !loading && Secondstep()}
        {next == 3 && !loading && Thirdsstep()}
      </div>
      <div className="loader-class">
        {loading && <LoaderUtility1 loading={loading} />}
      </div>
      <div>
        {modal && (
          <ModalComponent
            setModal={setModal}
            msg={msg}
            submit_smg={submit_smg}
            setSubmitmsg={setSubmitmsg}
            title={"Test"}
            status={status}
            onBack={() => setNaviagate(true)}
          />
        )}
        {navigate && <Navigate to={"/"} />}
      </div>
      {open && (
        <DialogAdd
          open={open}
          questionsarr={questionsarr}
          setQuestionsarr={setQuestionsarr}
          onClose={() => setOpen(false)}
          onClick={() => console.log("xjj")}
        />
      )}
    </>
  );
}
export default Createtest;
