import React, { useEffect, useState } from 'react'
import { fetchData } from '../ApiUtils/fetchData';


export const useGetAvailable = (userid, schedulestatus, teststatus) => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)

  const uri = `getAvailable?userid=${userid}&teststatus=${teststatus}&schedulestatus=${schedulestatus}`;

  async function fetchAvailable() {
    setLoader(true)
    const data = await fetchData(uri);
    setData(data)
    setLoader(false)
  }

  useEffect(() => {
    fetchAvailable()
  }, []);

  return { data, loader }

}


export const usePastAssessment = (userid, schedulestatus, teststatus) => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)

  const uri = `getAvailable?userid=${userid}&teststatus=${teststatus}&schedulestatus=${schedulestatus}`;

  async function fetchPastAssessment() {
    setLoader(true)
    const data = await fetchData(uri);
    setData(data)
    setLoader(false)
  }

  useEffect(() => {
    fetchPastAssessment()
  }, []);

  return { data, loader }
}