const { fetchData } = require("../../../ApiUtils/fetchData");
const { getValueFromSession } = require("../../../UtilityFunctions/getSetValueSession");

 export async function getTestBundles(userid){
    if(userid === undefined){
        userid = getValueFromSession('username')

    }

    try {
    
        let uri =`getbundles?userid=${userid}`
        let result = fetchData(uri)

        return result
    } catch (error) {
        console.log(error)
        return error
    }
    
}

 export async function getBundleTests(bundleid){

    try {
    
        let uri =`getbundletest?bundleid=${bundleid}`
        let result = fetchData(uri)

        return result
    } catch (error) {
        console.log(error)
        return error
    }
    
}


