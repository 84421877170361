import React, { useRef, useState } from "react";
import { nextSection, validTillTimeString, currDateChecker } from "../scheduleutils";
import DatePickerComp from "../DatePicker/DatePickerComp";
import TimePickerComp from "../TimePicker/TimePicker";
import MarkingScheme from "../MarkingScheme/MarkingScheme";
import ShowResult from "../ShowResult/ShowResult";
import { useNavigate, useSearchParams } from "react-router-dom";
import SendNotification from "../SendNotification/SendNotification";
import LoaderUtility from '../../UtilityFunctions/Loader_utility'
import useGetMarkingScheme from '../../hooks/useGetMarkingScheme'
import { ToastContainer, toast } from "react-toastify";

// import styles
import "../schedule.scss";
import { getISOString } from "../TimePicker/timePickerUtils";

const SectionOne = ({
  setDateValue,
  dateValue,
  selectedTime,
  title,
  settitle,
  setSelectMarkingScheme,
  selectMarkingScheme,
  setIsChecked,
  isChecked,
  setCurrentSection,
  currentSection,
  setSelectedTime,
  toShowTime,
  setToShowTime,
  isToNotify,
  setToNotify,
  endDateValue,
  setEndDateValue,
  endTime,
  setEndTime,
  toShowTimeEnd,
  setToShowTimeEnd,
  isEndDateChanged,
  isEndTimeChanged,
  setIsEndDateChanged,
  setIsEndTimeChanged,
}) => {
  const [searchParams] = useSearchParams();

  const from = searchParams?.get("from");
  const navigate = useNavigate();
  const plibcheck = sessionStorage.getItem("plibcheck");
  const puptstcheck = sessionStorage.getItem("puptstcheck");
  const check = from !== "testlibrary";
  const toRoute = check ? `/manageschedule` : -1;
  let  {markingSchemeArr, isLoading} = useGetMarkingScheme()
  const [isError, setError] = useState(false)
  const [msg, setMsg] = useState('')
  let toastId = useRef(null);
  const [color, setColor] = useState({})

  const checkDate= ( selectedTime, dateValue, endTime, endDateValue, isEndDateChanged, isEndTimeChanged)=>{
    setColor({})
    if (endTime !== null && endDateValue !== null ) {
      let  [combinedISOString, validTillISOString] = validTillTimeString(endTime, endDateValue);
      // const {endTimed, endDate} =currDateChecker(null,false)

      let scheduleDateTime= validTillTimeString(selectedTime, dateValue);
      let validtill = combinedISOString 
      // let validtill=(isEndDateChanged || isEndTimeChanged)?combinedISOString : validTillISOString
      console.log('str',validtill, scheduleDateTime[0],scheduleDateTime[1])
      if(scheduleDateTime[0] >= validtill){
        // setMsg('Start date should be before end date.')
        // setError(true)
        // toast.warn('Start date should be before end date.',{
        //   position:'top-center'
        // })
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Start date should be before end date.", {
            position: "top-center",
          });
        }
        setColor({startDate:'red'})
        return true
      }
      else if (validtill < scheduleDateTime[1]){
        console.log('str',validtill, scheduleDateTime[1],validTillISOString)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(`End time should be ${process.env.REACT_APP_HOURS> 0 ?process.env.REACT_APP_HOURS+' hrs':''}  ${process.env.REACT_APP_MINUTES> 0?process.env.REACT_APP_MINUTES+' minutes':''} .`, {
            position: "top-center",
          });
        }
        setColor({endTime:'red'})

        return true
      }
      }
      setError(false)
      return false
  }
  return (
    <>
    {isLoading?
    <LoaderUtility
    loading={isLoading}
    loadingMsg='Loading'
    />


    :

  
    <>
    <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
          />
      <section className="pyui_section_content">
        <span className="schedule_data_picker">
          <label>Title: </label>
          <input
            type="text"
            value={title}
            className="input"
            onChange={(e) => settitle(e.target.value)}
          ></input>
        </span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <p>and Time</p> */}
          <DatePickerComp title={'Select Start Date '} color ={color?.startDate || ''}setDateValue={setDateValue} value={dateValue} />

          <TimePickerComp
            setSelectedTime={setSelectedTime}
            selectedTime={selectedTime}
            setToShowTime={setToShowTime}
            toShowTime={toShowTime}
          />
        </span>
       {isError&& <p className="pyui-msg error">{msg}</p>}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <p>End Date And Time</p> */}
          <DatePickerComp title={'Select End Date '} setDateValue={(date)=>{
            setEndDateValue(date)
            setIsEndDateChanged(true)
            }} 
            startDate = {dateValue}
            gapInrem={1.2}
          value={endDateValue}
          color ={color?.endDate || ''}
          />
          <TimePickerComp
            setSelectedTime={(time)=>{
              
              setEndTime(time)
              setIsEndTimeChanged(true)
            }}
            selectedTime={endTime}
            setToShowTime={setToShowTimeEnd}
            toShowTime={toShowTimeEnd}
            color ={color?.endTime || ''}
          />
        </span>
        <MarkingScheme
          setSelectedValue={setSelectMarkingScheme}
          selectedValue={selectMarkingScheme}
          markingSchemeArr={markingSchemeArr}
        />
        <ShowResult setIsChecked={setIsChecked} isChecked={isChecked} />
        {check && (
          <SendNotification setToNotify={setToNotify} isToNotify={isToNotify} />
        )}
      </section>
      <footer className="schedule_main_footer">
        {from && (
          <button
            className="button link-button"
            onClick={() => {
              navigate(toRoute);
            }}
          >
            Back
          </button>
        )}
        <button
          className="button primary"
          onClick={() =>{ 
            
            if(checkDate( selectedTime, dateValue, endTime, endDateValue, isEndDateChanged, isEndTimeChanged)){
              return 
            }
            nextSection(setCurrentSection, currentSection)
          
          }}
        >
          Next
        </button>
      </footer>
      </>
      }
    </>
  )
}

export default SectionOne;
