import { useEffect, useMemo, useState } from 'react'
import { fetchData } from '../ApiUtils/fetchData';

const useTracks = () => {
  const [tracks, setTracks] = useState([]);

  async function fetchTracks() {
    const uri = `tracks`;
    const data = await fetchData(uri);
    console.log('tracks', tracks);

    setTracks(data)
  }

  useEffect(() => {
    fetchTracks()
  }, []);

  return { tracks }

}

export default useTracks