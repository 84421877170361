import Cards from "./Cards";
import LoaderUtility from "../Loader/Loader";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { useGetAvailable } from "../hooks/useUserAssessment";


function Available() {
  let userid = getValueFromSession('username')

  const { data, loader } = useGetAvailable(userid, 'started', 'available');
  // console.log('data', data);

  return <>
    {loader
      ? <LoaderUtility size={15} loading={loader} />
      : <Cards data={data} />}
  </>;
}

export default Available;
