import React from 'react'
import SelectInterval from './SelectInterval'
// import libraries
import TimePicker from 'react-time-picker'
// import styles
import './fromtimer.scss'

const FromTimePicker = ({ handleOnChange,
  toShowTime,
  stepOptions,
  setStepValue,
  stepValue,
  color
}) => {
  return (
    <section className='schedule_fromtimerpicker'>
      <div className='schedule_fromtimerpicker_selecttime'>
        <label htmlFor="">Select Time (12 Hr):</label>
        <div style={{
      border:color!== ''&&`2px solid ${color}`
    }}>

        <TimePicker
          onChange={handleOnChange}
          value={toShowTime}
          clockProps={{}}
          hourFormat="12"
          disableClock={true}
          minutePlaceholder="mm"
          hourPlaceholder="hh"
          secondPlaceholder='ss'
          format='hh:mm:ss a'
        />
          </div>
      </div>
      {/* <div className='schedule_fromtimerpicker_selectinterval'>
        <label>Select interval</label>
        <SelectInterval
          stepValue={stepValue}
          setStepValue={setStepValue}
          stepOptions={stepOptions}
        />
      </div> */}
    </section>
  )
}

export default FromTimePicker